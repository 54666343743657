import React from "react";
import { Icons } from "../assets";
import { Link } from "react-router-dom";
import { getExplorerURL } from "../utils/constants";
// import Button from "../components/Button";
// import SVGIcon from "../components/SVGIcon";

const Footer = () => {
  return (
    <footer className="fixed inset-x-0 bottom-0 block h-16 w-full bg-background sm:hidden">
      <div className="flex items-center justify-between p-4 text-sm font-normal text-white">
        <div className="flex items-center rounded-xl bg-gradient-to-t p-2 px-4 text-center hover:border-b hover:border-primary hover:from-[#574526] hover:via-[#2f2b25] hover:to-[#735F3D00] hover:text-primary">
          {/* <SVGIcon
            width="20"
            height="20"
            icon={Icons.WalletIcon}
            fill="#000"
            className="fill-primary"
          /> */}
          <a
            href={getExplorerURL()}
            target="_blank"
            className="flex items-center"
          >
            <img
              src={Icons.ExplorerIcon}
              className="fill-primary"
              alt="explorer icon"
              width={20}
            />

            <span className="ml-2">Explorer</span>
          </a>
        </div>

        <Link to={"/swap"}>
          <div className="flex items-center rounded-xl bg-gradient-to-t p-2 px-4 text-center hover:border-b hover:border-primary hover:from-[#574526] hover:via-[#2f2b25] hover:to-[#735F3D00] hover:text-primary">
            <img
              src={Icons.SwapIcon}
              alt="swap icon"
              className="fill-primary"
            />
            <span className="ml-2">SWAP</span>
          </div>
        </Link>
        <Link to={"/my-trades"}>
          <div className="flex items-center whitespace-nowrap rounded-xl bg-gradient-to-t p-2 px-4 text-center hover:border-b  hover:border-primary hover:from-[#574526] hover:via-[#2f2b25] hover:to-[#735F3D00] hover:text-primary">
            <img
              src={Icons.ListIcon}
              alt="list icon"
              className="fill-primary"
              width={20}
            />
            <span className="ml-2">MY TRADES</span>
          </div>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
