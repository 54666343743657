import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as SwapServerRequest from "../../api/SwapCrud";
import { Button, InputField } from "../../components/ui";
import { Gif, Icons, ReactIcons } from "../../assets";
import Dropdown from "react-dropdown";
import {
  FailedStatuses,
  ProviderAssetsLogo,
  TX_TABLE_HEADER,
} from "../../utils/constants";
import {
  copyTextToClipboard,
  toUTCDateFormat,
  truncateMiddle,
} from "../../utils/helper";
import { AxiosError } from "axios";
import { useSetRecoilState } from "recoil";
import { ErrorMessagesState } from "../../recoil/swap";
import moment from "moment";
import {
  getTokenLogoByCABN,
  getTokenSymbolByCABN,
} from "../../utils/CABNHelper";

const TransactionPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchInput = searchParams.get("search");
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<string>(searchInput || "");
  const [txs, setTxs] = useState<any[]>([]);
  const [counts, setCounts] = useState<number>(0);
  const setErrorMessage = useSetRecoilState(ErrorMessagesState);

  useEffect(() => {
    getTransactions(searchValue, (pageNumber - 1) * limit, limit);
  }, [searchValue, limit, pageNumber]);

  const calculateSwapTime = (tx: any): React.ReactNode => {
    const startTime = moment(tx?.createdAt);
    const endTime = moment(tx?.updatedAt);
    const durationInSeconds = moment
      .duration(endTime.diff(startTime))
      .asSeconds()
      .toFixed(0);
    return `${durationInSeconds} sec`;
  };

  const getTransactions = async (
    searchValue: string,
    offset: number,
    limit?: number,
  ) => {
    try {
      setLoading(true);
      const response =
        await SwapServerRequest.getSwapAndWithdrawTransactionsList(
          searchValue,
          offset,
          limit,
        );
      setTxs(response.data.body.swapAndWithdrawTransactions);
      setCounts(response.data.body.totalCounts);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching txs:", error);
      if (error instanceof AxiosError) {
        setErrorMessage(error?.response?.data?.status?.message);
      } else if (error instanceof Error) {
        setErrorMessage(error?.message);
      }
    }
  };

  const handleSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    searchParams.set("search", e.target.value);
    navigate(`?${searchParams.toString()}`);
  };

  const options = ["10", "15", "20"].map((value: any) => {
    return {
      value: value,
      label: <div className="text-primary">{value}</div>,
    };
  });

  return (
    <div>
      <div className="max-w-screen-xl">
        <header className="flex flex-col items-center text-center">
          <h2 className="text-xl text-primary">Multichain Swap Protocol</h2>
          <h1 className="text-4xl text-white">Live Transaction Tracker</h1>
          <p className="font-thin text-white">
            Monitor live transaction flows and details within the multiswap
            ecosystem, showcasing real-time activity and transaction statuses
            across various blockchains
          </p>
        </header>
        <div className="my-10 flex items-center">
          <InputField
            type="text"
            value={searchValue}
            placeholder="Search by Hash"
            className="ml-auto h-10 rounded-md bg-black"
            inputFieldClassName="px-2 py-1 text-backgroundLight rounded-md"
            postfix={
              <img
                src={Icons.SearchIcon}
                className="h-5 w-5"
                alt="search icon"
              />
            }
            onChange={(e) => handleSearchValue(e)}
          />
          <Button
            disabled={true}
            variant="sixth"
            title="filter"
            className="ml-2 !h-[30] cursor-not-allowed"
            prefix={<ReactIcons.LiaFilterSolid />}
          />
        </div>
        <main>
          <div className="relative mt-2 overflow-x-auto shadow-md">
            <table className="w-full text-left text-[10px] text-sm text-white rtl:text-right">
              <thead className="bg-inherit text-xs uppercase">
                <tr>
                  {TX_TABLE_HEADER.map((header) => (
                    <th scope="col" className="px-8 py-4">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="rounded-md">
                {loading ? (
                  <div className="flex items-center justify-center">
                    <img src={Gif.Loader} className="mr-2 h-4 w-4" />
                    <span className="text-white">Loading...</span>
                  </div>
                ) : txs?.length ? (
                  txs.map((tx, index) => (
                    <tr
                      className={`${index % 2 === 0 ? "bg-backgroundDark" : "bg-background"} text-[10px]`}
                    >
                      <td className="whitespace-nowrap px-8 py-2">
                        <div className="flex items-center">
                          <img
                            src={tx?.sourceNetwork?.logo}
                            className="h-5 w-5"
                            alt="network logo"
                          />
                          <img
                            src={getTokenLogoByCABN(tx?.sourceCabn)}
                            className="h-5 w-5"
                            alt="currency logo"
                          />
                          <p>{Number(tx?.sourceAmount).toFixed(6)}</p>
                          <p className="ml-2">
                            {getTokenSymbolByCABN(tx?.sourceCabn)}
                          </p>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-8 py-2">
                        <div className="flex items-center">
                          <img
                            src={tx?.destinationNetwork?.logo}
                            className="h-5 w-5"
                            alt="network logo"
                          />
                          <img
                            src={getTokenLogoByCABN(tx?.destinationCabn)}
                            className="h-5 w-5"
                            alt="currency logo"
                          />
                          <p>{Number(tx?.destinationAmount).toFixed(6)}</p>
                          <p className="ml-2">
                            {getTokenSymbolByCABN(tx?.destinationCabn)}
                          </p>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-8 py-2">
                        <p>{toUTCDateFormat(tx?.createdAt)}</p>
                      </td>
                      <td className="cursor-pointer py-2">
                        <div className="flex w-full items-center">
                          <p
                            onClick={() => {
                              copyTextToClipboard(tx?.receiveTransactionId);
                            }}
                          >
                            {tx?.receiveTransactionId &&
                              truncateMiddle(tx?.receiveTransactionId, 16)}
                          </p>
                          <a
                            href={`${tx?.sourceNetwork?.blockExplorerUrl}/tx/${tx?.receiveTransactionId}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={Icons.ExplorerUrlIcon}
                              alt="explorer url icon"
                              className="h-4 w-4 cursor-pointer"
                            />
                          </a>
                        </div>
                      </td>
                      <td className="cursor-pointer py-2">
                        <div className="flex w-full items-center">
                          <p
                            onClick={() => {
                              copyTextToClipboard(
                                tx?.withdrawTransactions[0]?.transactionId,
                              );
                            }}
                          >
                            {tx?.withdrawTransactions[0]?.transactionId &&
                              truncateMiddle(
                                tx?.withdrawTransactions[0]?.transactionId,
                                16,
                              )}
                          </p>
                          <a
                            href={`${tx?.destinationNetwork?.blockExplorerUrl}/tx/${tx?.withdrawTransactions[0]?.transactionId}`}
                            target="_black"
                            rel="noreferrer"
                          >
                            <img
                              src={Icons.ExplorerUrlIcon}
                              alt="explorer url icon"
                              className="h-4 w-4 cursor-pointer"
                            />
                          </a>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-8 py-2">
                        <p>~{calculateSwapTime(tx)}</p>
                      </td>
                      <td className="px-8 py-2">
                        {" "}
                        <div className="flex items-center">
                          <img
                            src={ProviderAssetsLogo[tx?.sourceAssetType]}
                            alt={"Network Logo"}
                            width={20}
                            height={20}
                          />{" "}
                          <img
                            src={ProviderAssetsLogo[tx?.destinationAssetType]}
                            alt={"network Logo"}
                            width={20}
                            height={20}
                          />
                        </div>
                      </td>
                      <td className="px-8 py-2">
                        {tx?.status === "swapWithdrawCompleted" ? (
                          <p className="rounded-md border border-[#2E9F57] bg-green-900 px-2 py-1">
                            Completed
                          </p>
                        ) : FailedStatuses.includes(tx?.status) ? (
                          <p className="rounded-md border border-red-800 bg-red-950 px-2 py-1">
                            Failed
                          </p>
                        ) : (
                          <p className="rounded-md border border-white bg-gray-500 px-2 py-1">
                            Pending
                          </p>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className="text-nowrap">Nothing to Show</div>
                )}
              </tbody>
            </table>
          </div>
        </main>
      </div>

      <div className="mx-5 mt-10 flex justify-between text-xs text-white">
        <div className="flex items-center">
          SHOW
          <Dropdown
            options={options}
            onChange={(e: any) => setLimit(Number(e?.value))}
            placeholder="10"
            placeholderClassName="!text-primary"
            menuClassName="!text-primary !bg-background font-thin text-sm !border-[#6F767E80]"
            controlClassName="!border-none !bg-transparent !text-primary !px-6"
          />
          TRANSACTIONS PER PAGE
        </div>
        <div className="flex items-center">
          <ReactIcons.IoIosArrowBack
            className="h-4 w-4 cursor-pointer"
            onClick={() => setPageNumber(pageNumber - 1)}
          />{" "}
          <span className="px-2">
            {pageNumber} OF {Math.ceil(counts / limit)}
          </span>
          <ReactIcons.IoIosArrowForward
            className="h-4 w-4 cursor-pointer"
            onClick={() => setPageNumber(pageNumber + 1)}
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionPage;
