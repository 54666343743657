import React from "react";
import Layout from "./layouts";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import SwapPage from "./page/swap/SwapPage";
import MyTradesPage from "./page/my-trades/MyTradesPage";
import SwapCompletedCard from "./page/swap-success/SwapCompletedCard";
import AbandonedTxPage from "./page/abandoned/AbandonedTxPage";
import TransactionPage from "./page/transaction/TransactionPage";
import ReferralPage from "./page/referral/ReferralPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/swap" replace />,
      },
      {
        path: "/swap",
        element: <SwapPage />,
      },
      {
        path: "/my-trades",
        element: <MyTradesPage />,
      },
      {
        path: "/transactions",
        element: <TransactionPage />,
      },
      {
        path: "/swap-success/:txHash",
        element: <SwapCompletedCard />,
      },
      {
        path: "/abandoned-tx",
        element: <AbandonedTxPage />,
      },
      {
        path: "/referral",
        element: <ReferralPage />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
