import React from "react";
import { Card } from "../../components/ui";
import { Icons } from "../../assets";
import { Tooltip } from "react-tooltip";
import { useRecoilValue } from "recoil";
import {
  destinationNetworkState,
  gasFeeEstimationState,
  sourceNetworkState,
} from "../../recoil/swap";

const SwapFeeDetailCard = ({
  platformFee,
  estimatedGasFee,
  sourceNetworkCurrency,
  estimatedSwapTime,
  slippage,
}: {
  platformFee: string;
  estimatedGasFee: string;
  sourceNetworkCurrency: string;
  estimatedSwapTime: string;
  slippage: number;
}) => {
  const estimatedGasFees = useRecoilValue(gasFeeEstimationState);
  const sourceNetwork = useRecoilValue(sourceNetworkState);
  const destinationNetwork = useRecoilValue(destinationNetworkState);

  const toolTipForGasFee = () => {
    if (sourceNetwork?.chainId === destinationNetwork?.chainId) {
      return `Est. Gas Fee: $${Number(estimatedGasFees?.source?.gasPriceInUSD).toFixed(2)}`;
    } else {
      return `Est. Source Gas Fee: $${Number(estimatedGasFees?.source?.gasPriceInUSD).toFixed(2)} & Est. Destination Gas Fee: $${Number(
        estimatedGasFees?.destination?.gasPriceInUSD,
      )?.toFixed(2)}`;
    }
  };

  const toolTipForSlippage = () => {
    if (sourceNetwork?.chainId === destinationNetwork?.chainId) {
      return `Slippage is currently set to ${Number(slippage).toFixed(2)}% on source networks`;
    } else {
      return `Slippage is currently set to ${Number(slippage).toFixed(2)}% on source and destination networks`;
    }
  };

  const toolTipForEstTime = () => {
    if (estimatedSwapTime) {
      return `Est. Time: ~${estimatedSwapTime} sec`;
    }
  };

  return (
    <Card
      variant="secondary"
      className="my-2 grid items-start gap-2 px-5 py-2 sm:grid-cols-2 sm:flex-row sm:items-center sm:justify-between"
    >
      <div className="flex flex-col gap-2">
        <div id="est-gas-fee" className="flex items-center text-xs sm:mt-0">
          <img src={Icons.GasIcon} alt="gas icon" className="mr-2" />
          <span className="mr-2 text-white">Est. Gas Fee:</span>
          <div className="ml-auto sm:ml-0">
            <span className="mr-2 text-primary">
              {Number(estimatedGasFee).toFixed(8)}
            </span>
            <span className="text-primary">{sourceNetworkCurrency}</span>
          </div>
          <Tooltip
            className="!w-auto !to-backgroundDark !text-[10px] !text-secondary"
            anchorSelect="#est-gas-fee"
          >
            {toolTipForGasFee()}
          </Tooltip>
        </div>
        <div id="platform-fee" className="flex items-center text-xs sm:mt-0">
          <img src={Icons.PlatformFeeIcon} alt="gas icon" className="mr-2" />
          <span className="mr-2 text-white">Platform fee:</span>
          <span className="ml-auto text-primary sm:ml-0">
            {platformFee ? Number(platformFee).toFixed(2) : "0"} USDC
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center text-xs sm:mt-0">
          <a id="slippage-fee">
            <img src={Icons.AboutIcon} alt="gas icon" className="mr-2" />
          </a>

          <span className="mr-2 text-white">Slippage:</span>
          <span className="ml-auto text-primary sm:ml-0">
            {Number(slippage).toFixed(2)}%
          </span>
          <Tooltip
            className="!w-auto !to-backgroundDark !text-[10px] !text-secondary"
            anchorSelect="#slippage-fee"
          >
            {toolTipForSlippage()}
          </Tooltip>
        </div>
        <div id="est-time" className="flex items-center text-xs sm:mt-0">
          <img src={Icons.TimerIcon} alt="gas icon" className="mr-2" />
          <span className="mr-2 text-white">Time:</span>
          <span className="ml-auto text-primary sm:ml-0">
            ~{estimatedSwapTime} sec
          </span>
          <Tooltip
            className="!w-auto !to-backgroundDark !text-[10px] !text-secondary"
            anchorSelect="#est-time"
          >
            {toolTipForEstTime()}
          </Tooltip>
        </div>
      </div>
    </Card>
  );
};

export default SwapFeeDetailCard;
