import React from "react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Lottie from "lottie-react";
import { Animations } from "../assets";
import {
  ErrorMessage,
  SuccessMessage,
  InfoMessage,
} from "../components/CustomMessages";

const index = () => {
  return (
    <>
      {/* <Lottie
        animationData={Animations.BackgroundGradient}
        className="absolute top-0 z-[-1]"
      /> */}
      <Navbar />
      <div
        className={`mb-24 flex h-screen flex-col items-center pt-28 sm:mb-0`}
      >
        <ErrorMessage />
        <SuccessMessage />
        <InfoMessage />
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default index;
