import { useEffect } from "react";
import { AxiosError } from "axios";
import * as authServer from "../api/AuthCrud";
import { useRecoilState } from "recoil";
import { authTokenState } from "../recoil/auth";

const useAuth = () => {
  const [authToken, setAuthToken] = useRecoilState(authTokenState);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(JSON.parse(token));
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        console.log(
          "authToken",
          "Refresh the token every 20 minutes",
          authToken,
        );
        if (authToken) {
          console.log("authToken", "refreshAuthToken");
          refreshAuthToken(authToken);
        }
      },
      1000 * 60 * 15,
    ); // Refresh the token every 15 minutes
    return () => clearInterval(intervalId);
  }, [authToken]);

  const setToken = (token: string) => {
    localStorage.setItem("authToken", JSON.stringify(token));
    setAuthToken(token);
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    setAuthToken("");
  };

  const refreshAuthToken = async (oldToken: string) => {
    try {
      console.log("authToken", "oldToken refreshAuthToken");
      const response = await authServer.refreshToken(oldToken);
      if (response) {
        console.log("authToken", response);
        const newAccessToken = response.data.body.token;
        setToken(newAccessToken);
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      if (error instanceof AxiosError) {
        console.log(error?.response?.data?.status?.message);
      } else if (error instanceof Error) {
        console.log(error?.message);
      }
    }
  };

  return { authToken, setToken, logout };
};

export default useAuth;
