import React, { useEffect, useState } from "react";
import { Card } from "../../components/ui/Card";
import { Gif, Icons, ReactIcons } from "../../assets";
import { Button } from "../../components/ui/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
  amountState,
  destinationNetworkState,
  destinationTokenState,
  sourceNetworkState,
  sourceTokenState,
  ErrorMessagesState,
} from "../../recoil/swap";
import { authTokenState } from "../../recoil/auth";
import * as swapServerRequest from "../../api/SwapCrud";
import Big from "big.js";
import { getTwitterPostContent } from "../../utils/constants";
import { Tooltip } from "react-tooltip";
import moment from "moment";
import { AxiosError } from "axios";
import { useSwitchNetwork } from "foundry";
import { addTokenToMetaMask } from "../../utils/Web3Helper";
import {
  getTokenLogoByCABN,
  getTokenSymbolByCABN,
} from "../../utils/CABNHelper";

const SwapCompletedCard = () => {
  let { txHash } = useParams();
  const navigate = useNavigate();
  const { switchWeb3Network } = useSwitchNetwork();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [txDetail, setTxDetail] = useState<any>(null);
  const authToken = useRecoilValue(authTokenState);
  const setErrorMessage = useSetRecoilState(ErrorMessagesState);
  const resetSourceNetwork = useResetRecoilState(sourceNetworkState);
  const resetDestinationNetwork = useResetRecoilState(destinationNetworkState);
  const resetSourceToken = useResetRecoilState(sourceTokenState);
  const resetDestinationToken = useResetRecoilState(destinationTokenState);
  const resetAmount = useResetRecoilState(amountState);

  useEffect(() => {
    if (authToken && txHash) {
      getTxDetails(txHash, authToken);
    }
  }, [txHash, authToken]);

  const getTxDetails = async (hash: string, authToken: string) => {
    try {
      setLoading(true);
      const txDetails = await swapServerRequest.getTransactionDetailByHash(
        hash,
        authToken,
      );
      setTxDetail(txDetails?.data?.body?.swapAndWithdrawTransaction);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error instanceof AxiosError) {
        setErrorMessage(error?.response?.data?.status?.message);
      } else if (error instanceof Error) {
        setErrorMessage(error?.message);
      }
    }
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const getGasFeeContentForInfo = () => {
    if (
      txDetail?.sourceNetwork?.chainId === txDetail?.destinationNetwork?.chainId
    ) {
      return `Est. Tx Fee: ${Number(txDetail?.gasPrices?.source?.gasPriceInUSD).toFixed(2)}$`;
    } else {
      return `Est. Source Tx Fee: ${Number(txDetail?.gasPrices?.source?.gasPriceInUSD).toFixed(2)}$ & Est. Destination Tx Fee: ${Number(
        txDetail?.gasPrices?.destination?.gasPriceInUSD,
      )?.toFixed(2)}$`;
    }
  };

  const getSlippageContentForInfo = () => {
    if (
      txDetail?.sourceNetwork?.chainId === txDetail?.destinationNetwork?.chainId
    ) {
      return `Slippage is currently set to ${Number(txDetail?.sourceSlippage)}% on source and destination networks`;
    } else {
      return `Slippage is currently set to ${Number(txDetail?.sourceSlippage + txDetail?.destinationSlippage)}% on source and destination networks`;
    }
  };

  const calculateSwapTime = (): React.ReactNode => {
    const startTime = moment(txDetail?.createdAt);
    const endTime = moment(txDetail?.updatedAt);
    const durationInSeconds = moment
      .duration(endTime.diff(startTime))
      .asSeconds()
      .toFixed(0);
    return `${durationInSeconds} sec`;
  };

  const switchNetworkAndAddToken = (chainId: string, tokenData: any) => {
    switchWeb3Network(chainId);
    addTokenToMetaMask(
      tokenData?.address,
      tokenData?.symbol,
      tokenData?.decimals,
      tokenData?.logo,
    );
  };

  if (loading) {
    return (
      <div className={`flex h-screen flex-col items-center bg-cover bg-center`}>
        <div className="flex flex-col items-start pb-20 pt-28 lg:flex-row">
          <div className="flex items-center">
            <img src={Gif.Loader} className="mr-2 h-4 w-4" />
            <span className="text-white">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Card variant="primary" className="">
        <div className="flex flex-col items-center">
          <p className="h-[48px] text-4xl font-normal text-primary">
            Congratulation!
          </p>
          <p className="mt-2 h-[31px] text-[26px] font-normal text-primary">
            Swap completed
          </p>
        </div>
        <div className="mt-8 flex items-center gap-1">
          <Card variant="secondary">
            <div className="relative flex flex-col px-3 py-5 sm:p-5">
              <p className="text-xs font-normal text-secondary">YOU PAY</p>
              <div className="flex items-center justify-between">
                <p className="text-xl font-normal text-secondary sm:text-2xl">
                  {Number(txDetail?.sourceAmount).toFixed(6)}
                </p>
                <a
                  href={`${txDetail?.sourceNetwork?.blockExplorerUrl}/tx/${txDetail?.receiveTransactionId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Icons.ExplorerUrlIcon}
                    alt="explorer url icon"
                    width={20}
                    className="cursor-pointer sm:absolute sm:right-5 sm:top-5"
                  />
                </a>
              </div>
              <div className="flex items-center gap-1">
                <p className="text-[9px] font-normal text-secondary sm:text-xs">
                  {getTokenSymbolByCABN(txDetail?.sourceCabn)}
                </p>
                <img
                  src={getTokenLogoByCABN(txDetail?.sourceCabn)}
                  alt="source token icon"
                  className="h-3 w-3 sm:h-4 sm:w-4"
                />
                <span className="text-[9px] font-normal text-secondary sm:text-xs">
                  {" "}
                  on {txDetail?.sourceNetwork?.name} Chain
                </span>
                <img
                  src={txDetail?.sourceNetwork?.logo}
                  alt="BSC"
                  className="h-3 w-3 sm:h-4 sm:w-4"
                />
              </div>
            </div>
          </Card>
          <div className="relative mx-auto flex items-center rounded-xl bg-[#11131580] p-[8px]">
            <ReactIcons.FaArrowRight color="white" />
          </div>
          <Card variant="secondary">
            <div className="relative flex flex-col px-3 py-5 sm:p-5">
              <p className="text-xs font-normal text-secondary">YOU RECEIVED</p>
              <div className="flex items-center justify-between">
                <p className="text-xl font-normal text-secondary sm:text-2xl">
                  {Number(txDetail?.destinationAmount).toFixed(6)}
                </p>
                <a
                  href={`${txDetail?.destinationNetwork?.blockExplorerUrl}/tx/${txDetail?.withdrawTransactions[0]?.transactionId}`}
                  target="_black"
                  rel="noreferrer"
                >
                  <img
                    src={Icons.ExplorerUrlIcon}
                    alt="explorer url icon"
                    width={20}
                    className="cursor-pointer sm:absolute sm:right-5 sm:top-5"
                  />
                </a>
              </div>
              <div className="flex items-center gap-1">
                <p className="text-[9px] font-normal text-secondary sm:text-xs">
                  {getTokenSymbolByCABN(txDetail?.destinationCabn)}
                </p>
                <img
                  src={getTokenLogoByCABN(txDetail?.destinationCabn)}
                  alt="destination token icon"
                  className="h-3 w-3 sm:h-4 sm:w-4"
                />
                <span className="text-[9px] font-normal text-secondary sm:text-xs">
                  {" "}
                  on {txDetail?.destinationNetwork?.name} Chain
                </span>
                <img
                  src={txDetail?.destinationNetwork?.logo}
                  alt="BSC"
                  className="h-3 w-3 sm:h-4 sm:w-4"
                />
              </div>
            </div>
          </Card>
        </div>
        <div className="hidden sm:flex sm:flex-col">
          <Card variant="secondary" className="mt-5 flex justify-between p-3">
            <div id="gas-fee" className="flex items-center gap-1">
              <img src={Icons.GasIcon} alt="" />
              <p className=" text-xs font-normal text-secondary">
                Gas Fee:{" "}
                <span className=" text-xs font-bold text-primary">
                  {txDetail?.gasPrices?.source?.gasPrice
                    ? new Big(Number(txDetail?.gasPrices?.source?.gasPrice))
                        .add(Number(txDetail?.gasPrices?.destination?.gasPrice))
                        .toFixed(10)
                    : undefined}
                </span>
                <span className="ml-2 text-xs font-bold text-primary">
                  {txDetail?.sourceNetwork?.networkCurrencySymbol}
                </span>{" "}
              </p>
              <Tooltip
                className="!w-auto !to-backgroundDark !text-[10px] !text-secondary"
                anchorSelect="#gas-fee"
              >
                {getGasFeeContentForInfo()}
              </Tooltip>
            </div>
            <div id="slippage-fee" className="flex items-center gap-1">
              <ReactIcons.PiWarningCircle className="h-[15px] w-[15px]" />
              <p className=" text-xs font-normal text-secondary">
                Slippage:{" "}
                <span className="text-xs font-bold text-primary">
                  {txDetail?.sourceNetwork?.chainId ===
                  txDetail?.destinationNetwork?.chainId
                    ? txDetail?.sourceSlippage
                    : txDetail?.sourceSlippage + txDetail?.destinationSlippage}
                  %
                </span>
              </p>
              <Tooltip
                className="!w-auto !to-backgroundDark !text-[10px] !text-secondary"
                anchorSelect="#slippage-fee"
              >
                {getSlippageContentForInfo()}
              </Tooltip>
            </div>
            <div className="flex items-center">
              <a id="est-time">
                <img src={Icons.TimerIcon} alt="timer icon" className="mr-2" />
              </a>
              <p className="text-xs font-normal text-secondary">
                Completed In:{" "}
                <span className="text-xs font-bold text-primary">
                  {calculateSwapTime()}
                </span>
              </p>
              <Tooltip
                className="!w-auto !to-backgroundDark !text-[10px] !text-secondary"
                anchorSelect="#est-time"
              >
                {`Est. time ${calculateSwapTime()}`}
              </Tooltip>
            </div>
          </Card>
          <Card
            variant="secondary"
            className="mt-5 flex items-center justify-between p-3"
          >
            <div className="flex items-center gap-1">
              <img
                src={Icons.ReceiverWalletIcon}
                alt="receiver wallet icon"
                className="mr-1"
              />
              <p className="text-xs font-normal text-secondary">
                Receiver Address:{" "}
                <span className=" text-xs font-bold text-primary">
                  {txDetail?.isSameNetworkSwap &&
                  !txDetail?.destinationWalletAddress
                    ? txDetail?.sourceWalletAddress
                    : txDetail?.destinationWalletAddress}
                </span>
              </p>
              <a
                href={`${txDetail?.destinationNetwork?.blockExplorerUrl}address/${txDetail?.destinationWalletAddress}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Icons.ExplorerUrlIcon}
                  alt="explorer url icon"
                  width={18}
                  className="ml-7"
                />
              </a>
            </div>
          </Card>
        </div>
        <div
          id="accordion-collapse"
          data-accordion="collapse"
          className="mt-2 rounded-md bg-[#11131580] sm:hidden"
        >
          <h2 id="accordion-collapse-heading-1">
            <button
              type="button"
              className="flex w-full items-center justify-between gap-3 p-2 px-4 text-sm text-white"
              data-accordion-target="#accordion-collapse-body-1"
              aria-expanded={isOpen ? "true" : "false"}
              aria-controls="accordion-collapse-body-1"
              onClick={toggleAccordion}
            >
              <span>Transaction Details</span>
              <svg
                data-accordion-icon
                className="h-3 w-3 shrink-0 rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5 5 1 1 5"
                />
              </svg>
            </button>
          </h2>
          <div
            id="accordion-collapse-body-1"
            className={`${isOpen ? "" : "hidden"}`}
            aria-labelledby="accordion-collapse-heading-1"
          >
            <div className="p-2 px-4">
              <div className="flex items-center justify-between gap-1 py-1">
                <div className="flex items-center">
                  <img src={Icons.GasIcon} alt="gas icon" className="mr-2" />
                  <p className="text-xs font-normal text-secondary">
                    Gas Fee:{" "}
                  </p>
                </div>
                <p className="text-xs font-normal">
                  <span className=" text-xs font-bold text-primary">
                    {txDetail?.gasPrices?.source?.gasPrice
                      ? new Big(Number(txDetail?.gasPrices?.source?.gasPrice))
                          .add(
                            Number(txDetail?.gasPrices?.destination?.gasPrice),
                          )
                          .toFixed()
                      : undefined}
                  </span>
                  <span className="ml-2 text-xs font-bold text-primary">
                    {txDetail?.sourceNetwork?.networkCurrencySymbol}
                  </span>{" "}
                </p>
              </div>
              <div className="flex items-center justify-between gap-1 py-1">
                <div className="flex items-center">
                  <img
                    src={Icons.AboutIcon}
                    className="mr-2 h-[15px] w-[15px]"
                    alt="about icon"
                  />
                  <p className=" text-xs font-normal text-secondary">
                    Slippage:{" "}
                  </p>
                </div>
                <p className="text-xs font-bold text-primary">
                  {txDetail?.sourceNetwork?.chainId ===
                  txDetail?.destinationNetwork?.chainId
                    ? txDetail?.sourceSlippage
                    : txDetail?.sourceSlippage + txDetail?.destinationSlippage}
                  %
                </p>
              </div>
              <div className="flex items-center justify-between py-1">
                <div className="flex items-center">
                  <img
                    src={Icons.TimerIcon}
                    alt="timer icon"
                    className="mr-2"
                  />
                  <p className="text-xs font-normal text-secondary">Time: </p>
                </div>
                <p className="text-xs font-bold text-primary">~89 sec</p>
              </div>
              <div className="flex items-center gap-1 py-1">
                <img
                  src={Icons.ReceiverWalletIcon}
                  alt="receiver wallet icon"
                  className="mr-2"
                />
                <p className="text-xs font-normal text-secondary">
                  Receiver Address:{" "}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <p className=" text-xs font-bold text-primary">
                  {txDetail?.destinationWalletAddress}
                </p>
                <a
                  href={`${txDetail?.sourceNetwork?.blockExplorerUrl}address/${txDetail?.destinationWalletAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Icons.ExplorerUrlIcon}
                    alt="explorer url icon"
                    width={18}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <Button
          variant="primary"
          title="CONTINUE"
          className="mt-[30px] w-full"
          onClick={() => {
            resetSourceNetwork();
            resetDestinationNetwork();
            resetSourceToken();
            resetDestinationToken();
            resetAmount();
            navigate("/swap");
          }}
        />
        <div className="mt-[40px] flex items-center justify-center">
          <div
            className="flex cursor-pointer items-center justify-between"
            onClick={() => {}}
          >
            <img
              src={Icons.MetamaskIcon}
              alt=""
              className="h-[16px] w-[17px]"
            />
            <div
              className="ml-2 text-[14px] font-normal text-secondary"
              onClick={() =>
                switchNetworkAndAddToken(
                  txDetail?.destinationNetwork?.chainId,
                  {
                    address: txDetail?.destinationCabn?.tokenContractAddress,
                    symbol: txDetail?.destinationCabn?.currency?.symbol,
                    decimals: txDetail?.destinationCabn?.currency?.decimals,
                    logo: txDetail?.destinationCabn?.currency?.logo,
                  },
                )
              }
            >
              Add to Metamask
            </div>
          </div>
          <div className="mr-2 flex items-center justify-between">
            <a
              href={getTwitterPostContent(
                txDetail?.sourceToken?.symbol,
                txDetail?.sourceNetwork?.name,
                txDetail?.destinationToken?.symbol,
                txDetail?.destinationNetwork?.name,
              )}
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-between"
            >
              <ReactIcons.FaXTwitter className="ml-2 mr-2 h-[14px] w-[18px] text-secondary" />
              <p className="text-[14px] font-normal text-secondary">
                Share on X
              </p>
            </a>
          </div>
        </div>
        <p className=" mt-[35px] text-center text-[14px] font-normal text-secondary">
          See transaction details on the
          <Link
            to={"/my-trades"}
            className="ml-2 text-[14px] font-normal text-primary"
          >
            My Trades page
          </Link>
        </p>
      </Card>
    </>
  );
};

export default SwapCompletedCard;
