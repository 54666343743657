export const DEFAULT_TOKEN_LOGO =
  "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/Token-import.png";

export const SwapAndWithdrawTxStatuses: { [key: string]: string } = {
  generatorSignatureCreated: "Generator Signature Created",
  generatorSignatureFailed: "Swap failed!",
  validatorSignatureCreated: "Validator Signature Created",
  validatorSignatureFailed: "Swap failed!",
  masterValidationFailed: "Swap failed!",
  swapPending: "Tx Processing - Please Wait",
  swapCreated: "Swap Created",
  swapCompleted: "Tx Processing - Please Wait",
  swapFailed: "Swap failed! Please try again.",
  swapWithdrawGenerated: "Swap Withdraw Generated",
  swapWithdrawPending: "Tx Processing - Please Wait",
  swapWithdrawFailed: "Swap Withdraw Failed",
  swapWithdrawCompleted: "Swap Withdraw Completed",
};

export const SwapAndWithdrawErrorMessages: { [key: string]: string } = {
  generatorSignatureFailed: "Generator Signature Failed",
  validatorSignatureFailed: "Validator Signature Failed",
  masterValidationFailed: "Master Validation Failed",
  swapFailed: "Swap Failed",
  swapWithdrawFailed: "Swap Withdraw Failed",
};

export const FailedStatuses = [
  "generatorSignatureFailed",
  "validatorSignatureFailed",
  "masterValidationFailed",
  "swapFailed",
  "swapWithdrawFailed",
];

export const getTwitterPostContent = (
  sourceTokenSymbol: string,
  sourceNetworkName: string,
  destinationTokenSymbol: string,
  destinationNetworkName: string,
) => {
  return `https://twitter.com/intent/post?text=%F0%9F%9A%80%20Just%20used%20%400xMultiSwap%20by%20%40FerrumNetwork%0A%20to%20swap%20%24${sourceTokenSymbol}%20on%20%23${sourceNetworkName}%20for%20%24${destinationTokenSymbol}%20on%20%23${destinationNetworkName}%20with%20a%20click%20on%20a%20button.%0A%0ABlazing%20fast%20swaps%20and%20super%20easy%20to%20use%21%20%F0%9F%94%A5%0A%0A%F0%9F%91%89Try%20it%20yourself%3A%0Ahttps%3A%2F%2Fswap.multiswap.network%0A%0A%24FRM%20%24SWAPS`;
};

export const getSwapFailedHelpLink = () =>
  "https://help.ferrumnetwork.io/en/articles/9053975-swap-failed";

export const getSwapInsufficientFundsHelpLink = () =>
  "https://help.ferrumnetwork.io/en/articles/9128013-intrinsic-gas-too-low-this-usually-means-you-do-not-have-enough-gas";

export const getDocsURL = () =>
  "https://docs.ferrumnetwork.io/ferrum-network-ecosystem/v/multiswap-and-multichain-liquidity-pool-bridge/";

export const getExplorerURL = () => process.env.REACT_APP_ANALYTICS_URL;

export const getSupportLink = () => "https://discord.gg/u3qjKEq7Mp";

export const ProviderAssetsLogo: { [key: string]: string } = {
  Foundary: "https://etherscan.io/token/images/ferrumnetwork_32.png?=v454",
  "1Inch": "https://etherscan.io/token/images/1inch_32.png?=v454",
};

export const getDiscordSupportLink = () =>
  "https://discord.com/invite/HEfKq57asd";

export const TABLE_HEADER = [
  "FROM",
  "TO",
  "DATE UTC",
  "FROM HASH",
  "TO HASH",
  "PROVIDER",
  "STATUS",
  "DETAILS",
];

export const TX_TABLE_HEADER = [
  "FROM",
  "TO",
  "DATE UTC",
  "FROM HASH",
  "TO HASH",
  "COMPLETED TIME",
  "PROVIDER",
  "STATUS",
];

export const FRM_CONTRACT_ADDRESS: { [key: string]: string } = {
  "56": "0xa719b8ab7ea7af0ddb4358719a34631bb79d15dc",
  "42161": "0x9f6abbf0ba6b5bfa27f4deb6597cc6ec20573fda",
};
