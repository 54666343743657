import React, { useEffect, useState } from "react";
import { Card } from "./ui";
import { Icons, ReactIcons } from "../assets";
import { useRecoilState } from "recoil";
import {
  ErrorMessagesState,
  SuccessMessagesState,
  infoMessagesState,
} from "../recoil/swap";

interface MessageProps {
  message: string;
}

interface ClosableMessageProps extends MessageProps {
  onClose: () => void;
}

export const ErrorMessage: React.FC = () => {
  const [message, setMessage] = useRecoilState(ErrorMessagesState);

  useEffect(() => {
    if (message !== "") {
      setTimeout(() => setMessage(""), 10000);
    }
  }, [message]);

  if (message === "" || message === null || message === undefined) return null;

  return (
    <Card
      variant="primary"
      className="mb-5 flex items-center justify-between border border-[#E27A7A40] !p-3"
    >
      <img src={Icons.ErrorIcon} alt="Error" />

      <p className="mx-2 w-11/12 text-[#E27A7A]">{message}</p>

      <ReactIcons.IoMdClose
        className="cursor-pointer"
        size={20}
        color="white"
        onClick={() => setMessage("")}
      />
    </Card>
  );
};

export const SuccessMessage: React.FC = () => {
  const [message, setMessage] = useRecoilState(SuccessMessagesState);

  useEffect(() => {
    if (message !== "") {
      setTimeout(() => setMessage(""), 1000);
    }
  }, [message]);

  if (message === "") return null;

  return (
    <Card
      variant="info"
      className="mb-5 flex !w-auto items-center justify-between border border-[#1cc74740] !p-3"
    >
      <ReactIcons.IoIosCheckmarkCircle className="text-green-600" size={25} />
      <p className="mx-2 w-11/12 text-[#e8d5d5]">{message}</p>

      <ReactIcons.IoMdClose
        className="cursor-pointer"
        size={20}
        color="white"
        onClick={() => setMessage("")}
      />
    </Card>
  );
};
// Success message
// export const SuccessMessage: React.FC<ClosableMessageProps> = ({
//   message,
//   onClose,
// }) => {
//   const [closed, setClosed] = useState(false);

//   if (closed) return null;
//   return (
//     <Card
//       variant="primary"
//       className=" mb-5 flex h-[50px] items-center justify-between border border-[#E27A7A40]"
//     >
//       <span>
//         <img src={Icons.ErrorIcon} alt="Error" />
//       </span>
//       <div className="text-[#E27A7A]">{message}</div>
//       <span>
//         <img src={ReactIcons.MdError} alt="X" />
//       </span>
//     </Card>
//   );
// };
export const RejectionError: React.FC<ClosableMessageProps> = ({
  message,
  onClose,
}) => {
  const [closed, setClosed] = useState(false);

  if (closed) return null;
  return (
    <Card
      variant="primary"
      className=" mb-5 flex h-[50px] items-center justify-between border border-[#FFCC6A40]"
    >
      <img src={Icons.ErrorIconRejection} alt="Error" />
      <p className="mt-[2px] text-[#D5A342]">{message}</p>
      <ReactIcons.IoMdClose
        className="cursor-pointer"
        onClick={() => {
          onClose();
          setClosed(true);
        }}
      />
    </Card>
  );
};

// Info message
export const InfoMessage: React.FC = () => {
  const [info, setInfo] = useRecoilState(infoMessagesState);

  useEffect(() => {
    if (info.message !== "" && info.keepVisible === false) {
      setTimeout(() => setInfo({ message: "", keepVisible: false }), 10000);
    }
  }, [info.message]);

  if (
    info.message === "" ||
    info.message === null ||
    info.message === undefined
  )
    return null;

  return (
    <Card
      variant="primary"
      className="mb-5 flex !w-auto items-center justify-between border border-[#3C6BA740] !p-3"
    >
      <img src={Icons.ErrorIconInfo} alt="info icon" />
      <p className="mx-2 w-11/12 text-[#509AF8]">{info.message}</p>
      <ReactIcons.IoMdClose
        className="cursor-pointer"
        color="white"
        onClick={() => {
          setInfo({ message: "", keepVisible: false });
        }}
      />
    </Card>
  );
};

// Warning message
export const WarningMessage: React.FC<ClosableMessageProps> = ({
  message,
  onClose,
}) => {
  const [closed, setClosed] = useState(false);

  if (closed) return null;
  return (
    <Card
      variant="primary"
      className=" mb-5 flex h-[50px] items-center justify-between border border-[#FFCC6A40]"
    >
      <img src={Icons.ErrorIconWarning} alt="Error" />
      <p className="mt-[2px] font-bold text-[#D5A342]">{message}</p>
      <ReactIcons.IoMdClose
        className="cursor-pointer"
        onClick={() => {
          onClose();
          setClosed(true);
        }}
      />
    </Card>
  );
};
