import React, { useState } from "react";
import { Button, Card } from "../../components/ui";
import { ReactIcons } from "../../assets";
import { copyTextToClipboard } from "../../utils/helper";
import * as ReferralServerRequest from "../../api/ReferralCrud";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authTokenState } from "../../recoil/auth";
import { useWalletConnector } from "foundry";
import { AxiosError, AxiosResponse } from "axios";
import { ErrorMessagesState, SuccessMessagesState } from "../../recoil/swap";

const ReferralPage = () => {
  const authToken = useRecoilValue(authTokenState);
  const setErrorMessage = useSetRecoilState(ErrorMessagesState);
  const setSuccessMessage = useSetRecoilState(SuccessMessagesState);
  const [referralCode, setReferralCode] = useState<string>("");
  const { isConnected } = useWalletConnector();

  const handleGenerateReferralCode = async (authToken: string) => {
    try {
      const response: AxiosResponse =
        await ReferralServerRequest.generateReferralCode(authToken);
      setReferralCode(response?.data?.body?.referral?.code);
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        setErrorMessage(error?.response?.data?.status?.message);
      } else if (error instanceof Error) {
        setErrorMessage(error?.message);
      }
    }
  };

  return (
    <Card variant="primary">
      <h3 className="text-white">Referral Generator</h3>

      {referralCode && authToken && isConnected ? (
        <div className="mt-4 flex items-center text-white">
          <p className="text-primary">{referralCode}</p>
          <ReactIcons.GoCopy
            className="ml-2 cursor-pointer"
            onClick={() => {
              setSuccessMessage("Referral code copied to clipboard");
              copyTextToClipboard(
                `${window.location.host}/swap?referralCode=${referralCode}`,
              );
            }}
          />
        </div>
      ) : (
        <Button
          variant={isConnected ? "primary" : "tertiary"}
          className="mt-4"
          title={
            isConnected
              ? "Generate Referral Code"
              : "Please Connect Your Wallet to Generate Referral Code"
          }
          disabled={!isConnected || !authToken}
          onClick={() => handleGenerateReferralCode(authToken)}
        />
      )}
    </Card>
  );
};

export default ReferralPage;
