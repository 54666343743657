import { DEFAULT_TOKEN_LOGO } from "./constants";

export const getTokenLogoByCABN = (token: any) => {
  if (token?.isDefault) {
    return token?.currency?.logo;
  } else {
    return DEFAULT_TOKEN_LOGO;
  }
};

export const getTokenSymbolByCABN = (token: any) => {
  if (token?.isDefault) {
    return token?.currency?.symbol;
  } else {
    return token?.nonDefaultCurrencyInformation?.symbol;
  }
};
