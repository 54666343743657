import axios from "axios";
const GATEWAY_BACKEND_URL = process.env.REACT_APP_GATEWAY_BACKEND_URL;

export function generateReferralCode(authToken: string) {
  return axios.post(
    `${GATEWAY_BACKEND_URL}/api/v1/community-member/multiSwap/referrals/create/referral/code`,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
}
