import Web3 from "web3";
import { AbiItem } from "web3-utils";
import FerrumJson from "../utils/abi/erc20.json";
import ApproveRouterJson from "./abi/ApproveRouterAbi.json";
import Big from "big.js";

export class Web3Helper {
  web3Client: Web3;

  constructor(web3: Web3 | any) {
    this.web3Client = web3;
  }

  async sendSwapTransactionAsync(payload: any, dispatch?: any) {
    return new Promise((resolve, reject) => {
      this.web3Client.eth
        .sendTransaction(payload)
        // .on("transactionHash", (transactionHash) => {
        //   console.log("transactionHash", transactionHash);
        //   if (transactionHash) {
        //     console.log({ transactionHash });
        //     resolve(transactionHash);
        //   }
        // })
        // .on("receipt", (receipt) => {
        //   if (receipt.transactionHash) {
        //     console.log("receipt", receipt);
        //   }
        // })
        .then((data: any) => {
          if (data && data.transactionHash) {
            console.log("swap completed::", data);
          }
        })
        .catch((e: any) => {
          if (dispatch) {
            dispatch(e);
          }
          console.log("failed", e);
          reject(e);
        });
    });
  }
  async getStandardContractAllocation(
    tokenContractAddress: string,
    walletAddress: string,
    fiberRouterSmartContractAddress: string | null,
  ) {
    if (tokenContractAddress) {
      const contractInstance = new this.web3Client.eth.Contract(
        ApproveRouterJson.abi as AbiItem[],
        tokenContractAddress,
      );
      const response = await contractInstance.methods
        .allowance(walletAddress, fiberRouterSmartContractAddress)
        .call();
      if (Number(response) > 0) {
        const decimals = await contractInstance.methods.decimals().call();
        const decimalFactor = 10 ** Number(decimals);
        const allowance = new Big(Number(response))
          .div(decimalFactor)
          .toFixed();
        if (allowance) {
          return { isApproved: true, allowance };
        }
      }
      return {
        isApproved: false,
        allowance: response,
      };
    }
  }
  async fetchNativeTokenBalance(walletAddress: string) {
    try {
      let balance: any = await this.web3Client.eth.getBalance(walletAddress);
      balance = this.web3Client.utils.fromWei(balance, "ether");
      return balance;
    } catch (e) {
      console.log("fetch Native Token Balance error", e);
    }
  }

  async fetchTokenBalance(tokenAddress: string, walletAddress: string) {
    try {
      const tokenContract = new this.web3Client.eth.Contract(
        FerrumJson.abi as any[],
        tokenAddress,
      );
      let balance: string = await tokenContract.methods
        .balanceOf(walletAddress)
        .call();
      let dec = await tokenContract.methods.decimals().call();
      const decimalFactor = 10 ** Number(dec);
      balance = new Big(balance).div(decimalFactor).toFixed(Number(dec));
      return balance;
    } catch (error) {
      if (error instanceof Error) {
        console.log("fetch Token Balance error", error?.message, tokenAddress);
      }
    }
  }

  async fetchTokenDetail(tokenAddress: string) {
    try {
      const tokenData: any = {
        tokenAddress: tokenAddress,
      };
      const tokenContract = new this.web3Client.eth.Contract(
        FerrumJson.abi as any[],
        tokenAddress,
      );
      const [symbol, decimals, name] = await Promise.all([
        tokenContract.methods.symbol().call(),
        tokenContract.methods.decimals().call(),
        tokenContract.methods.name().call(),
      ]);
      tokenData.decimals = decimals;
      tokenData.name = name;
      tokenData.symbol = symbol;
      return tokenData;
    } catch (e) {
      console.log("error", e);
    }
  }
}

export const addTokenToMetaMask = async (
  address: string,
  symbol: string,
  decimals: string,
  image: string,
) => {
  try {
    console.log("add token", address, symbol, decimals, image);
    const ethereum = (window as any).ethereum;
    if (ethereum) {
      await ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address,
            symbol,
            decimals,
            image,
          },
        },
      });
    }
  } catch (e) {
    console.error("Add token", e);
  }
};
