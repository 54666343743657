import React, { useEffect, useState } from "react";
import { Card, InputField } from "../../components/ui";
import { ReactIcons, Icons, Gif } from "../../assets";
import * as SwapServerRequest from "../../api/SwapCrud";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authTokenState } from "../../recoil/auth";
import {
  copyTextToClipboard,
  toUTCDateFormat,
  truncateMiddle,
} from "../../utils/helper";
import {
  FailedStatuses,
  ProviderAssetsLogo,
  TABLE_HEADER,
} from "../../utils/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import TradeDetailDialog from "../../components/TradeDetailDialog";
import { AxiosError } from "axios";
import { ErrorMessagesState, SuccessMessagesState } from "../../recoil/swap";
import Dropdown from "react-dropdown";
import {
  getTokenLogoByCABN,
  getTokenSymbolByCABN,
} from "../../utils/CABNHelper";

const MyTradesPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchInput = searchParams.get("search");
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [counts, setCounts] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>(searchInput || "");
  const [trades, setTrades] = useState<any[]>([]);
  const [selectedTrade, setSelectedTrade] = useState<any>();
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const authToken = useRecoilValue(authTokenState);
  const setErrorMessage = useSetRecoilState(ErrorMessagesState);
  const setSuccessMessage = useSetRecoilState(SuccessMessagesState);

  useEffect(() => {
    if (authToken) {
      getUserTrades(authToken, (pageNumber - 1) * limit, limit, searchValue);
    }
  }, [authToken, searchValue, limit, pageNumber]);

  const getUserTrades = async (
    authToken: string,
    offset: number,
    limit: number,
    txHash?: string,
  ) => {
    try {
      setLoading(true);
      const response =
        await SwapServerRequest.getAllSwapAndWithdrawTransactions(
          authToken,
          offset,
          limit,
          txHash,
        );
      setCounts(response.data.body.totalCount);
      setTrades(response.data.body.swapAndWithdrawTransactions);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching trades:", error);
      if (error instanceof AxiosError) {
        setErrorMessage(error?.response?.data?.status?.message);
      } else if (error instanceof Error) {
        setErrorMessage(error?.message);
      }
    }
  };

  const handleSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    searchParams.set("search", e.target.value);
    navigate(`?${searchParams.toString()}`);
  };

  const options = ["10", "15", "20"].map((value: any) => {
    return {
      value: value,
      label: <div className="text-primary">{value}</div>,
    };
  });

  return (
    <>
      <Card variant="primary" size="lg">
        <header className="flex items-center">
          <ReactIcons.IoArrowBack
            color="white"
            size={25}
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h1 className="ml-4 text-sm text-white sm:text-xl">My Trades</h1>
          <InputField
            type="text"
            value={searchValue}
            placeholder="Search by Hash"
            className="ml-auto bg-backgroundDark"
            inputFieldClassName="px-2 py-1 text-white rounded-md"
            postfix={
              <img
                src={Icons.SearchIcon}
                className="h-5 w-5"
                alt="search icon"
              />
            }
            onChange={(e) => handleSearchValue(e)}
          />
        </header>
        <main>
          <div className="relative mt-2 overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full table-auto text-left text-[10px] text-sm text-white rtl:text-right">
              <thead className="bg-inherit text-xs uppercase">
                <tr>
                  {TABLE_HEADER.map((header) => (
                    <th scope="col" className="px-8 py-4">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="overflow-auto rounded-md">
                {loading ? (
                  <div className="flex items-center">
                    <img src={Gif.Loader} className="mr-2 h-4 w-4" />
                    <span className="text-white">Loading...</span>
                  </div>
                ) : trades.length ? (
                  trades.map((trade, index) => (
                    <tr
                      className={`${index % 2 === 0 ? "bg-backgroundDark" : ""} text-[10px]`}
                    >
                      <td className="whitespace-nowrap px-8 py-2">
                        <div className="flex items-center">
                          <img
                            src={trade?.sourceNetwork?.logo}
                            className="h-5 w-5"
                            alt="network logo"
                          />
                          <img
                            src={getTokenLogoByCABN(trade?.sourceCabn)}
                            className="h-5 w-5"
                            alt="currency logo"
                          />
                          <p>{Number(trade?.sourceAmount).toFixed(6)}</p>
                          <p className="ml-2">
                            {getTokenSymbolByCABN(trade?.sourceCabn)}
                          </p>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-8 py-2">
                        <div className="flex items-center">
                          <img
                            src={trade?.destinationNetwork?.logo}
                            className="h-5 w-5"
                            alt="network logo"
                          />
                          <img
                            src={getTokenLogoByCABN(trade?.destinationCabn)}
                            className="h-5 w-5"
                            alt="currency logo"
                          />
                          <p>{Number(trade?.destinationAmount).toFixed(6)}</p>
                          <p className="ml-2">
                            {getTokenSymbolByCABN(trade?.destinationCabn)}
                          </p>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-8 py-2">
                        <p>{toUTCDateFormat(trade?.createdAt)}</p>
                      </td>
                      <td className="cursor-pointer px-6 py-2">
                        <div className="flex items-center">
                          <p
                            onClick={() => {
                              copyTextToClipboard(trade?.receiveTransactionId);
                              setSuccessMessage("copied");
                            }}
                          >
                            {trade?.receiveTransactionId &&
                              truncateMiddle(trade?.receiveTransactionId, 16)}
                          </p>
                          <a
                            href={`${trade?.sourceNetwork?.blockExplorerUrl}/tx/${trade?.receiveTransactionId}`}
                            target="_blank"
                            rel="noreferrer"
                            className="ml-2 h-4 w-4"
                          >
                            <img
                              src={Icons.ExplorerUrlIcon}
                              alt="explorer url icon"
                              className="cursor-pointer"
                            />
                          </a>
                        </div>
                      </td>
                      <td className="cursor-pointer px-6 py-2">
                        <div className="flex items-center">
                          <p
                            onClick={() => {
                              copyTextToClipboard(
                                trade?.withdrawTransactions[0]?.transactionId,
                              );
                              setSuccessMessage("copied");
                            }}
                          >
                            {trade?.withdrawTransactions[0]?.transactionId &&
                              truncateMiddle(
                                trade?.withdrawTransactions[0]?.transactionId,
                                16,
                              )}
                          </p>
                          <a
                            href={`${trade?.destinationNetwork?.blockExplorerUrl}/tx/${trade?.withdrawTransactions[0]?.transactionId}`}
                            target="_black"
                            rel="noreferrer"
                            className="ml-2 h-4 w-4"
                          >
                            <img
                              src={Icons.ExplorerUrlIcon}
                              alt="explorer url icon"
                              className="cursor-pointer"
                            />
                          </a>
                        </div>
                      </td>
                      <td className="px-8 py-2">
                        {" "}
                        <div className="flex items-center">
                          <img
                            src={ProviderAssetsLogo[trade?.sourceAssetType]}
                            alt={"Network Logo"}
                            width={20}
                            height={20}
                          />{" "}
                          <img
                            src={
                              ProviderAssetsLogo[trade?.destinationAssetType]
                            }
                            alt={"network Logo"}
                            width={20}
                            height={20}
                          />
                        </div>
                      </td>
                      <td className="px-8 py-2">
                        {trade?.status === "swapWithdrawCompleted" ? (
                          <p className="rounded-md border border-[#2E9F57] bg-green-900 px-2 py-1">
                            Completed
                          </p>
                        ) : FailedStatuses.includes(trade?.status) ? (
                          <p className="rounded-md border border-red-800 bg-red-950 px-2 py-1">
                            Failed
                          </p>
                        ) : (
                          <p className="rounded-md border border-white bg-gray-500 px-2 py-1">
                            Pending
                          </p>
                        )}
                      </td>
                      <td className="cursor-pointer px-8 py-2">
                        <img
                          onClick={() => {
                            setSelectedTrade(trade);
                            setShowDetail(true);
                          }}
                          src={Icons.DetailIcon}
                          className="h-5 w-5"
                          alt="detail icon"
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className="text-nowrap">Nothing to Show</div>
                )}
              </tbody>
            </table>
          </div>
        </main>
        <div className="mx-5 mb-10 mt-5 flex justify-between text-[10px] text-white sm:text-sm">
          <div className="flex items-center">
            SHOW
            <Dropdown
              options={options}
              onChange={(e: any) => setLimit(Number(e?.value))}
              placeholder="10"
              placeholderClassName="!text-primary"
              menuClassName="!text-primary !bg-background font-thin text-[10px] sm:text-sm !border-[#6F767E80]"
              controlClassName="!border-none !bg-transparent !text-primary !pr-6 !pl-2"
            />
            TRANSACTIONS PER PAGE
          </div>
          <div className="flex items-center">
            <ReactIcons.IoIosArrowBack
              className="h-4 w-4 cursor-pointer"
              onClick={() => setPageNumber(pageNumber - 1)}
            />{" "}
            <span className="px-2">
              {pageNumber} OF {Math.ceil(counts / limit)}
            </span>
            <ReactIcons.IoIosArrowForward
              className="h-4 w-4 cursor-pointer"
              onClick={() => setPageNumber(pageNumber + 1)}
            />
          </div>
        </div>
      </Card>

      <TradeDetailDialog
        trade={selectedTrade}
        show={showDetail}
        onHide={() => setShowDetail(false)}
      />
    </>
  );
};

export default MyTradesPage;
