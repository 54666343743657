import React, { useCallback, useEffect, useState } from "react";
import Web3 from "web3";
import { Web3Helper } from "../utils/Web3Helper";

const useTokenBalances = (
  walletAddress: string,
  tokenList: [{ [key: string]: string }] | any[],
  network: any,
) => {
  const [tokenBalances, setTokenBalances] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fetchBalances = async () => {
    setLoading(true);
    const web3 = new Web3(network?.rpcUrl);
    const web3Helper = new Web3Helper(web3);
    const balances: any = {};
    await Promise.all(
      tokenList.map(async (token) => {
        if (
          network?.chainId === token?.network?.chainId &&
          token?.tokenContractAddress &&
          (Web3.utils.isAddress(token?.tokenContractAddress) || token?.isNative)
        ) {
          let balance: string | undefined = undefined;
          if (token?.isNative) {
            balance = await web3Helper.fetchNativeTokenBalance(walletAddress);
          } else {
            balance = await web3Helper.fetchTokenBalance(
              token?.tokenContractAddress,
              walletAddress,
            );
          }
          if (Number(balance) > 0) {
            balances[
              `${token?.network.chainId}:${token?.tokenContractAddress}`
            ] = balance;
          }
        }
      }),
    );
    setTokenBalances(balances);
    setLoading(false);
  };

  useEffect(() => {
    if (tokenList && tokenList.length > 0 && walletAddress) {
      fetchBalances();
    }
  }, [tokenList, walletAddress, network?.chainId]);

  return { loading, tokenBalances, error };
};
export default useTokenBalances;
