import axios from "axios";
const GATEWAY_BACKEND_URL = process.env.REACT_APP_GATEWAY_BACKEND_URL;

export async function getCABNsList(cabnListParameters: any, token?: string) {
  return axios.get(
    `${GATEWAY_BACKEND_URL}/api/v1/currencies/cabn/bulk/list?search=${
      cabnListParameters?.search ? cabnListParameters.search : ""
    }&isAllowedOnMultiSwap=true&network=${
      cabnListParameters?.networkId ? cabnListParameters.networkId : ""
    }&offset=${
      cabnListParameters?.offset ? cabnListParameters.offset : ""
    }&limit=${
      cabnListParameters?.limit ? cabnListParameters.limit : ""
    }&isPagination=${
      cabnListParameters?.isPagination ? cabnListParameters.isPagination : ""
    }&tokenContractAddress=${
      cabnListParameters?.tokenContractAddress
        ? cabnListParameters.tokenContractAddress
        : ""
    }&chainId=${
      cabnListParameters?.chainId ? cabnListParameters.chainId : ""
    }&priority=${
      cabnListParameters?.priority ? cabnListParameters.priority : ""
    }`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function createUserCustomCABN(data: any, authToken: string) {
  return axios.post(
    `${GATEWAY_BACKEND_URL}/api/v1/community-member/multiSwap/cabns/create`,
    {
      tokenContractAddress: data.tokenAddress?.toLowerCase(),
      chainId: data?.chainId,
      currencyName: data?.name,
      currencySymbol: data?.symbol,
      decimals: Number(data?.decimals),
    },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
}
