import { useEffect, useState } from "react";
import { Dialog } from "./ui";
import { useConnector } from "foundry";
import { getWallets } from "../api/WalletCrud";
import { useSetRecoilState } from "recoil";
import { selectedWalletState } from "../recoil/wallet";

const ConnectWalletDialog = ({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) => {
  const [wallets, setWallets] = useState([]);
  const setSelectedWallet = useSetRecoilState(selectedWalletState);
  const {
    metaMaskClickEvent,
    walletConnectClickEvent,
    rainbowWalletClickEvent,
    trustWalletClickEvent,
    zerionClickEvent,
    myEterWalletClickEvent,
  } = useConnector();

  const connectWalletHandler = (wallet: any) => {
    console.log("wallet", wallet?.name);
    setSelectedWallet(wallet);
    if (wallet?.name === "MetaMask") {
      metaMaskClickEvent();
    } else if (wallet?.name === "WalletConnect") {
      walletConnectClickEvent();
    } else if (wallet?.name === "Rainbow") {
      rainbowWalletClickEvent();
    } else if (wallet?.name === "Trust") {
      trustWalletClickEvent();
    } else if (wallet?.name === "Zerion") {
      zerionClickEvent();
    } else if (wallet?.name === "Enkrypt") {
      myEterWalletClickEvent();
    }
  };

  const fetchWallets = async () => {
    try {
      const response = await getWallets();
      setWallets(response.data.body.walletByNetworks);
    } catch (error) {
      console.error("Error fetching wallets:", error);
    }
  };

  useEffect(() => {
    fetchWallets();
  }, []);

  return (
    <>
      <Dialog
        show={show}
        onHide={onHide}
        title="Connect Wallet"
        showCloseButton
      >
        <div className="my-4 grid grid-cols-3 gap-3 sm:grid sm:grid-cols-4">
          {Array.isArray(wallets) &&
            wallets.map((walletObj: any, index: any) => {
              const wallet = walletObj.wallet;
              if (window.innerWidth < 640 && wallet.name === "MetaMask")
                return null;
              return (
                <div
                  className="flex h-[70px] w-[70px] cursor-pointer flex-col items-center justify-center rounded-lg hover:bg-backgroundDark"
                  key={index}
                  onClick={() => connectWalletHandler(wallet)}
                >
                  <img
                    className="h-6 w-6 cursor-pointer"
                    src={wallet.logo}
                    alt={wallet.name}
                  />
                  <div className="mt-2 cursor-pointer text-[9px] text-primaryLight">
                    {wallet.name}
                  </div>
                </div>
              );
            })}
        </div>
        {/* <div className="mt-3 h-3 text-center">
          <p className="text-[10px] font-normal text-primaryLight">
            Use at your own risk.
          </p>
        </div> */}
      </Dialog>
    </>
  );
};

export default ConnectWalletDialog;
