import React from "react";
import { Card } from "../../components/ui";
import { Icons } from "../../assets";

const SwapPlatformFeeCard = ({ platformFee }: { platformFee: string }) => {
  return (
    <Card
      variant="secondary"
      className="my-2 grid items-start gap-2 px-5 py-2 sm:grid-cols-2 sm:flex-row sm:items-center sm:justify-between"
    >
      <div id="platform-fee" className="flex items-center text-xs sm:mt-0">
        <img src={Icons.PlatformFeeIcon} alt="gas icon" className="mr-2" />
        <span className="mr-2 text-white">Platform fee:</span>
        <span className="ml-auto text-primary sm:ml-0">
          {platformFee ? Number(platformFee).toFixed(2) : "0"} USDC
        </span>
      </div>
    </Card>
  );
};

export default SwapPlatformFeeCard;
