import React, { useState } from "react";
import { Dialog, Button, InputField } from "./ui";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import { destinationTokenState, sourceTokenState } from "../recoil/swap";

const SlippageDialog = ({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showCustom, setShowCustom] = useState(false);
  const [sourceSlippage, setSourceSlippage] = useState(
    searchParams.get("sourceSlippage") || "",
  );
  const [destinationSlippage, setDestinationSlippage] = useState(
    searchParams.get("destinationSlippage") || "",
  );
  const sourceToken = useRecoilValue(sourceTokenState);
  const destinationToken = useRecoilValue(destinationTokenState);

  const handlePercentSlippage = (percent: string) => {
    searchParams.set("sourceSlippage", percent);
    searchParams.set("destinationSlippage", percent);
    navigate(`?${createSearchParams(searchParams)}`);
    onHide();
  };

  const handleAutoSlippage = () => {
    searchParams.set("sourceSlippage", sourceToken?.sourceSlippage || "2");
    searchParams.set(
      "destinationSlippage",
      destinationToken?.destinationSlippage || "2",
    );
    navigate(`?${createSearchParams(searchParams)}`);
    onHide();
  };

  const handleClose = () => {
    searchParams.set("sourceSlippage", sourceSlippage);
    searchParams.set("destinationSlippage", destinationSlippage);
    navigate(`?${createSearchParams(searchParams)}`);
    setShowCustom(false);
    onHide();
  };

  const handleSourceSlippageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (
      e.target.value === "" ||
      (Number(e.target.value) >= 0 && Number(e.target.value) <= 50)
    ) {
      setSourceSlippage(e.target.value);
    }
  };

  const handleDestinationSlippageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (
      e.target.value === "" ||
      (Number(e.target.value) >= 0 && Number(e.target.value) <= 50)
    ) {
      setDestinationSlippage(e.target.value);
    }
  };

  return (
    <Dialog
      show={show}
      onHide={handleClose}
      title="Slippage tolerance"
      showCloseButton
    >
      <div className="mt-3 flex justify-between gap-2 sm:gap-5">
        {!showCustom ? (
          <>
            <Button
              variant="quinary"
              title="1%"
              rounded="md"
              className="h-10 w-20 cursor-pointer py-1"
              onClick={() => handlePercentSlippage("1")}
            />
            <Button
              variant="quinary"
              title="2%"
              rounded="md"
              className="h-10 w-20 cursor-pointer py-1"
              onClick={() => handlePercentSlippage("2")}
            />
            <Button
              variant="quaternary"
              title="Custom"
              rounded="md"
              className="h-10 w-20 cursor-pointer py-1"
              onClick={() => setShowCustom(true)}
            />
          </>
        ) : (
          <>
            <div>
              <label className="text-nowrap text-[10px] text-white">
                Source
              </label>
              <InputField
                type="text"
                maxLength={4}
                value={sourceSlippage}
                placeholder="2"
                postfix={"%"}
                inputFieldClassName="w-full !pr-0 px-1 sm:px-3"
                className="h-10 w-[3.2rem] rounded-md border border-backgroundLight bg-backgroundDark text-sm font-medium text-white sm:w-20"
                onChange={handleSourceSlippageChange}
              />
            </div>
            <div>
              <label className="text-nowrap text-[10px] text-white">
                Target
              </label>
              <InputField
                type="text"
                maxLength={4}
                value={destinationSlippage}
                placeholder="2"
                postfix={"%"}
                inputFieldClassName="w-full !pr-0 px-1 sm:px-3"
                className="h-10 w-[3.2rem] rounded-md border border-backgroundLight bg-backgroundDark text-sm font-medium text-white sm:w-20"
                onChange={handleDestinationSlippageChange}
              />
            </div>
            <div>
              <label className="text-nowrap text-[10px] text-white">
                Total
              </label>
              <InputField
                type="text"
                value={String(
                  Number(sourceSlippage) + Number(destinationSlippage),
                )}
                disabled={true}
                placeholder=""
                postfix={"%"}
                inputFieldClassName="w-full !pr-0 px-1 sm:px-3"
                className="h-10 w-[3.2rem] rounded-md border border-backgroundLight bg-backgroundDark text-sm font-medium text-white sm:w-20"
              />
            </div>
          </>
        )}
        <Button
          variant="primary"
          title="Auto"
          rounded="md"
          className="h-10 w-20 cursor-pointer self-end  border-primary bg-primary py-1"
          onClick={handleAutoSlippage}
        />
      </div>

      <div className="mt-5 flex flex-col flex-wrap gap-5">
        <div className="flex h-[22px] justify-between">
          <p className=" text-primaryLight">Auto refresh</p>

          <label className="inline-flex cursor-pointer items-center">
            <input type="checkbox" value="" className="peer sr-only" />
            <div className="rtl:peer-checked:after:-translate-x-ful peer relative h-6 w-11 rounded-full bg-backgroundDark after:absolute after:start-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:bg-backgroundLight after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white dark:peer-focus:ring-transparent"></div>
          </label>
        </div>
        <div className="flex h-[22px] justify-between">
          <p className=" text-primaryLight">Disable multihops</p>

          <label className="inline-flex cursor-not-allowed items-center ">
            <input type="checkbox" value="" className="peer sr-only" disabled />
            <div className="rtl:peer-checked:after:-translate-x-ful peer relative h-6 w-11 rounded-full bg-backgroundDark after:absolute after:start-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:bg-primary after:transition-all after:content-[''] peer-checked:bg-backgroundDark peer-checked:after:translate-x-full peer-checked:after:border-white dark:peer-focus:ring-transparent"></div>
          </label>
        </div>
      </div>
    </Dialog>
  );
};

export default SlippageDialog;
