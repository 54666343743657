import React, { useEffect } from "react";
import { Gif, Icons, ReactIcons } from "../../assets";
import { Card } from "../../components/ui";
import { useRecoilValue } from "recoil";
import {
  amountState,
  sourceNetworkState,
  sourceTokenState,
} from "../../recoil/swap";
import { DEFAULT_TOKEN_LOGO } from "../../utils/constants";

const ApproveCard = () => {
  const amount = useRecoilValue(amountState);
  const sourceNetwork = useRecoilValue(sourceNetworkState);
  const sourceToken = useRecoilValue(sourceTokenState);

  return (
    <Card variant="primary">
      <section className="flex flex-col">
        <div className="mx-auto pt-2">
          <img src={Gif.Loader} alt="Loader" className="w-[100px]" />
        </div>
        <div className="mt-10 text-center">
          <p className="text-[22px] font-normal text-primary">
            {`Approve ${sourceToken?.isDefault ? sourceToken?.currency?.symbol : sourceToken?.nonDefaultCurrencyInformation?.symbol} Spending`}
          </p>
        </div>
        <div className="mt-5">
          <p className="text-center text-[34px] font-normal text-secondary">
            {amount}
          </p>
          <div className="mx-auto mt-[11.5px] flex h-[20px] w-[200px] justify-between">
            <div className="flex items-center justify-between gap-1">
              <img
                src={sourceNetwork?.logo}
                alt="BSC"
                className=" h-[18px] w-[18px]"
              />
              <span className="text-[14px] font-normal text-secondary">
                {sourceNetwork?.name}
              </span>
            </div>
            <img src={Icons.MdArrowForward} alt="ForwarArrow" />
            <div className="flex items-center justify-between gap-1">
              <img
                src={
                  sourceToken?.isDefault
                    ? sourceToken?.currency?.logo
                    : DEFAULT_TOKEN_LOGO
                }
                alt="BUSD"
                className=" h-[18px] w-[18px]"
              />
              <span className="text-[14px] font-normal text-secondary">
                {sourceToken?.isDefault
                  ? sourceToken?.currency?.symbol
                  : sourceToken?.nonDefaultCurrencyInformation?.symbol}
              </span>
            </div>
          </div>
        </div>
        <div className="mx-auto mt-5 flex h-[97px] w-[317px] flex-col items-center justify-center gap-10">
          <p className=" mt-5 text-base font-normal text-secondary">
            Confirm in Your Wallet to Proceed
          </p>
          <p className="flex items-center gap-1 text-xs font-normal text-secondary opacity-[50%]">
            Why is Approval Necessary?{" "}
            <span>
              <a
                href="https://help.ferrumnetwork.io/en/articles/8975395-what-is-an-approval-transaction"
                target="_blank"
              >
                <ReactIcons.IoOpenOutline className="h-[18px] w-[18px] cursor-pointer" />
              </a>
            </span>
          </p>
        </div>
      </section>
    </Card>
  );
};

export default ApproveCard;
