import clsx from "clsx";
import React from "react";

type InputType = "number" | "text" | "integer" | "numeric";
type InputMode =
  | "decimal"
  | "numeric"
  | "text"
  | "search"
  | "tel"
  | "url"
  | "email"
  | "none";

export const InputField = ({
  type,
  placeholder,
  disabled = false,
  value,
  inputMode,
  maxLength,
  min,
  max,
  className,
  inputFieldClassName,
  prefix,
  postfix,
  onClick,
  onChange,
}: {
  type: InputType;
  placeholder: string;
  value: string;
  inputMode?: InputMode;
  maxLength?: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  className?: string;
  inputFieldClassName?: string;
  prefix?: string | JSX.Element;
  postfix?: string | JSX.Element;
  onClick?: () => void;
  onChange?: (e: any) => void;
}) => {
  const inputFieldClasses = clsx(
    inputFieldClassName,
    `bg-inherit focus:outline-none ${prefix ? "pl-10" : ""} ${postfix ? "pr-10" : ""}`,
  );
  const classes = clsx(className, "relative flex items-center");

  return (
    <div className={classes}>
      {prefix && <span className="absolute start-0 mx-2">{prefix}</span>}
      <input
        type={type}
        inputMode={inputMode}
        placeholder={placeholder}
        disabled={disabled}
        className={inputFieldClasses}
        value={value}
        maxLength={maxLength}
        min={min}
        max={max}
        onClick={onClick}
        onChange={onChange}
      />
      {postfix && <span className="absolute end-0 mx-2">{postfix}</span>}
    </div>
  );
};
