import axios from "axios";
import { getTokenForFiber } from "../utils/helper";

const GATEWAY_BACKEND_URL = process.env.REACT_APP_GATEWAY_BACKEND_URL;
const FIBER_BACKEND_URL = process.env.REACT_APP_FIBER_BACKEND_URL;
const REACT_APP_MS_ANALYTICS_BACKEND_URL =
  process.env.REACT_APP_MS_ANALYTICS_BACKEND_URL;

export function getTokenCategorizedAndQuoteInformation(
  sourceWalletAddress: string,
  sourceTokenContractAddress: string,
  sourceNetworkChainId: string,
  sourceAmount: string,
  destinationAddress: string,
  destinationTokenContractAddress: string,
  destinationNetworkChainId: string,
  sourceSlippage: string,
  destinationSlippage: string,
  referralCode?: string,
  signal?: any,
) {
  return axios.get(
    `${FIBER_BACKEND_URL}/api/v1/multiswap/token/categorized/quote/info?sourceWalletAddress=${sourceWalletAddress}&sourceTokenContractAddress=${sourceTokenContractAddress}&sourceNetworkChainId=${sourceNetworkChainId}&sourceAmount=${sourceAmount}&destinationTokenContractAddress=${destinationTokenContractAddress}&destinationNetworkChainId=${destinationNetworkChainId}&destinationWalletAddress=${destinationAddress}&sourceSlippage=${sourceSlippage}&destinationSlippage=${destinationSlippage}&referralCode=${referralCode ? referralCode : ""}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${getTokenForFiber()}`,
      },
      signal, // Pass the signal to the request
    },
  );
}

export function getFakeTokenCategorizedAndQuoteInformation(
  sourceWalletAddress: string,
  sourceTokenContractAddress: string,
  sourceNetworkChainId: string,
  sourceAmount: string,
  destinationAddress: string,
  destinationTokenContractAddress: string,
  destinationNetworkChainId: string,
  sourceSlippage: string,
  destinationSlippage: string,
  gasEstimationDestinationAmount: string,
) {
  return axios.get(
    `${FIBER_BACKEND_URL}/api/v1/multiswap/token/categorized/quote/info?sourceWalletAddress=${sourceWalletAddress}&sourceTokenContractAddress=${sourceTokenContractAddress}&sourceNetworkChainId=${sourceNetworkChainId}&sourceAmount=${sourceAmount}&destinationTokenContractAddress=${destinationTokenContractAddress}&destinationNetworkChainId=${destinationNetworkChainId}&destinationWalletAddress=${destinationAddress}&gasEstimationDestinationAmount=${gasEstimationDestinationAmount}&sourceSlippage=${sourceSlippage}&destinationSlippage=${destinationSlippage}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${getTokenForFiber()}`,
      },
    },
  );
}

export function getSwapApi(
  sourceWalletAddress: string,
  sourceTokenContractAddress: string,
  sourceNetworkChainId: string,
  sourceAssetType: string,
  sourceAmount: string,
  sourceAmountIn: string,
  sourceAmountOut: string,
  sourceOneInchData: string,
  sourceOneInchSelector: string,
  destinationWalletAddress: string,
  destinationNetworkChainId: string,
  destinationTokenContractAddress: string,
  destinationAssetType: string,
  destinationOneInchData: string,
  destinationOneInchSelector: string,
  destinationAmountIn: string,
  destinationAmountOut: string,
  destinationAmount: string,
  gasPrice: string,
  isCCTP: boolean,
  feeDistribution: any,
) {
  return axios.post(
    `${FIBER_BACKEND_URL}/api/v1/multiswap/swap/signed?sourceWalletAddress=${sourceWalletAddress}&sourceTokenContractAddress=${sourceTokenContractAddress}&sourceNetworkChainId=${sourceNetworkChainId}&destinationTokenContractAddress=${destinationTokenContractAddress}&destinationNetworkChainId=${destinationNetworkChainId}&sourceAmount=${sourceAmount}&destinationWalletAddress=${destinationWalletAddress}&sourceAssetType=${sourceAssetType}&destinationAssetType=${destinationAssetType}&sourceOneInchData=${sourceOneInchData}&destinationOneInchData=${destinationOneInchData}&destinationAmountIn=${destinationAmountIn}&destinationAmountOut=${destinationAmountOut}&destinationAmount=${destinationAmount}&gasPrice=${gasPrice}&sourceOneInchSelector=${sourceOneInchSelector}&destinationOneInchSelector=${destinationOneInchSelector}&sourceAmountIn=${sourceAmountIn}&sourceAmountOut=${sourceAmountOut}&isCCTP=${isCCTP}`,
    { feeDistribution },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${getTokenForFiber()}`,
      },
    },
  );
}

export function getEstimatedSwapTime() {
  return axios.get(
    `${REACT_APP_MS_ANALYTICS_BACKEND_URL}/v1/analytics/getTransactionAndUserAnalytics`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
      },
    },
  );
}

export async function doSwapAndWithdrawTxAfterSwapApi(
  transactionHash: string,
  sourceNetworkId: string,
  sourceCabnId: string,
  sourceAssetType: string,
  sourceAmount: string,
  sourceWalletAddress: string,
  sourceBridgeAmount: string,
  sourceOneInchData: string,
  sourceOneInchSelector: string,
  destinationNetworkId: string,
  destinationCabnId: string,
  destinationAssetType: string,
  destinationOneInchData: string,
  destinationAmountIn: string,
  destinationAmountOut: string,
  destinationOneInchSelector: string,
  gasPrices: { [key: string]: any },
  sourceSlippage: string,
  destinationSlippage: string,
  authToken: string,
  isCCTP: boolean,
) {
  return axios.post(
    `${GATEWAY_BACKEND_URL}/api/v1/community-member/multiSwap/transactions/do/swap/and/withdraw/${transactionHash}?sourceNetworkId=${sourceNetworkId}&destinationNetworkId=${destinationNetworkId}&sourceCabnId=${sourceCabnId}&destinationCabnId=${destinationCabnId}&sourceWalletAddress=${sourceWalletAddress}`,
    {
      sourceAssetType: sourceAssetType,
      destinationAssetType: destinationAssetType,
      sourceAmount: sourceAmount,
      sourceWalletAddress: sourceWalletAddress,
      sourceBridgeAmount: sourceBridgeAmount,
      destinationAmountIn: destinationAmountIn,
      destinationAmountOut: destinationAmountOut,
      sourceOneInchData: sourceOneInchData,
      destinationOneInchData: destinationOneInchData,
      gasPrices: gasPrices,
      sourceOneInchSelector,
      destinationOneInchSelector,
      sourceSlippage: sourceSlippage,
      destinationSlippage: destinationSlippage,
      isCCTP: isCCTP,
    },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
}

export async function getGasFee(
  sourceNetworkChainId: string,
  sourceTokenContractAddress: string,
  sourceAmount: string,
  sourceAmountIn: string,
  sourceAmountOut: string,
  sourceOneInchData: string,
  sourceAssetType: string,
  sourceOneInchSelector: string,
  sourceWalletAddress: string,
  destinationNetworkChainId: string,
  destinationTokenContractAddress: string,
  destinationOneInchSelector: string,
  destinationAmountIn: string,
  destinationAmountOut: string,
  destinationOneInchData: string,
  destinationAssetType: string,
  destinationWalletAddress: string,
  originalDestinationAmountIn: string,
  originalDestinationAmountOut: string,
  isCCTP: boolean,
  feeDistribution: any,
) {
  return axios.post(
    `${FIBER_BACKEND_URL}/api/v1/gasFees/estimation?destinationNetworkChainId=${destinationNetworkChainId}&destinationWalletAddress=${destinationWalletAddress}&destinationTokenContractAddress=${destinationTokenContractAddress}&destinationAmountIn=${destinationAmountIn}&destinationAmountOut=${destinationAmountOut}&destinationAssetType=${destinationAssetType}&destinationOneInchData=${destinationOneInchData}&sourceNetworkChainId=${sourceNetworkChainId}&sourceTokenContractAddress=${sourceTokenContractAddress}&sourceAmount=${sourceAmount}&sourceOneInchData=${sourceOneInchData}&sourceAssetType=${sourceAssetType}&sourceWalletAddress=${sourceWalletAddress}&sourceOneInchSelector=${sourceOneInchSelector}&destinationOneInchSelector=${destinationOneInchSelector}&sourceAmountIn=${sourceAmountIn}&sourceAmountOut=${sourceAmountOut}&isCCTP=${isCCTP}`,
    {
      feeDistribution: feeDistribution,
      originalDestinationAmountIn: originalDestinationAmountIn,
      originalDestinationAmountOut: originalDestinationAmountOut,
    },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${getTokenForFiber()}`,
      },
    },
  );
}
export async function getTransactionDetailByHash(
  hash: string,
  authToken: string,
) {
  return axios.get(
    `${GATEWAY_BACKEND_URL}/api/v1/community-member/multiSwap/transactions/${hash}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
}

export async function getAllSwapAndWithdrawTransactions(
  authToken: string,
  offset: number,
  limit: number = 10,
  txId?: string,
) {
  return axios.get(
    `${GATEWAY_BACKEND_URL}/api/v1/community-member/multiSwap/transactions/list?transactionHash=${txId}&offset=${offset}&limit=${limit}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
}

export async function regenerateSwap(
  authToken: string,
  txHash: string,
  chainId: string,
) {
  return axios.put(
    `${GATEWAY_BACKEND_URL}/api/v1/community-member/multiSwap/transactions/regenerate/swap/and/withdraw/${txHash}?chainId=${chainId}`,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
}

export async function getSwapAndWithdrawTransactionsList(
  search?: string,
  offset?: number,
  limit?: number,
) {
  return axios.get(
    `${GATEWAY_BACKEND_URL}/api/v1/swapTransactions/transactions/list?search=${search}&offset=${offset}&limit=${limit}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
      },
    },
  );
}
