import React, { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Icons } from "../../assets";
import clsx from "clsx";

const Portal = ({ children }: { children: ReactNode }) => {
  return createPortal(children, document.body);
};

type Size = "sm" | "md" | "lg";

interface DialogProps {
  show: boolean;
  onHide: () => void;
  children: ReactNode;
  className?: string;
  size?: Size;
  title: string | ReactNode;
  showCloseButton?: boolean;
}
export const Dialog: React.FC<DialogProps> = ({
  show,
  onHide,
  children,
  className,
  size = "md",
  title,
  showCloseButton,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const classes = clsx(
    "border-xl fixed left-1/2 top-1/2 z-50 flex flex w-full -translate-x-1/2 -translate-y-1/2 transform flex-col rounded rounded-md bg-background bg-background p-5 p-6 shadow-lg md:flex-col",
    {
      "max-w-[400px] sm:max-w-[580px]": size === "lg",
      "max-w-[300px] sm:max-w-[400px]": size === "md",
      "max-w-[300px]": size === "sm",
    },
    className,
  );

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onHide();
    }
  };

  if (!show) return null;

  return (
    <Portal>
      <div className="fixed inset-0 z-40 bg-black bg-opacity-50 backdrop-blur-sm backdrop-filter">
        <section className={classes} ref={ref}>
          <header className="flex justify-between">
            <p className="text-xl font-normal text-white">{title}</p>
            {showCloseButton && (
              <button onClick={onHide}>
                <img src={Icons.CrossIcon} alt="cross icon" className="h-4" />
              </button>
            )}
          </header>
          <article>{children}</article>
        </section>
      </div>
    </Portal>
  );
};
