import React from "react";
import { Card, Dialog } from "./ui";
import { Icons, Images, ReactIcons } from "../assets";
import Big from "big.js";
import { copyTextToClipboard, truncateMiddle } from "../utils/helper";
import { FailedStatuses } from "../utils/constants";
import moment from "moment";
import { Tooltip } from "react-tooltip";

const TradeDetailDialog = ({
  trade,
  show,
  onHide,
}: {
  trade: any;
  show: boolean;
  onHide: () => void;
}) => {
  const calculateSwapTime = (): React.ReactNode => {
    const startTime = moment(trade?.createdAt);
    const endTime = moment(trade?.updatedAt);
    const durationInSec = moment
      .duration(endTime.diff(startTime))
      .asSeconds()
      .toFixed(0);
    return durationInSec;
  };

  const toolTipForGasFee = () => {
    if (trade?.sourceNetwork?.chainId === trade?.destinationNetwork?.chainId) {
      return `Est. Tx Fee: ${Number(trade?.gasPrices?.source?.gasPriceInUSD).toFixed(2)}$`;
    } else {
      return `Est. Source Tx Fee: ${Number(trade?.gasPrices?.source?.gasPriceInUSD).toFixed(2)}$ & Est. Destination Tx Fee: ${Number(
        trade?.gasPrices?.destination?.gasPriceInUSD,
      )?.toFixed(2)}$`;
    }
  };

  const toolTipForSlippage = () => {
    if (trade?.sourceNetwork?.chainId === trade?.destinationNetwork?.chainId) {
      return `Slippage is currently set to ${Number(trade?.sourceSlippage)}% on source networks`;
    } else {
      return `Slippage is currently set to ${Number(trade?.sourceSlippage + trade?.destinationSlippage)}% on source and destination networks`;
    }
  };
  // console.log({ trade });
  return (
    <Dialog
      show={show}
      onHide={onHide}
      title={
        <div className="flex items-center">
          <h1>MultiSwap</h1>
          {trade?.status === "swapWithdrawCompleted" ? (
            <p className="ml-2 rounded-md border border-[#2E9F57] bg-green-900 px-2 py-1 text-sm">
              Completed
            </p>
          ) : FailedStatuses.includes(trade?.status) ? (
            <p className="ml-2 rounded-md border border-red-800 bg-red-950 px-2 py-1 text-sm">
              Failed
            </p>
          ) : (
            <p className="ml-2 rounded-md border border-white bg-gray-500 px-2 py-1 text-sm">
              Pending
            </p>
          )}
        </div>
      }
      showCloseButton
      size="lg"
    >
      <div className="mt-8 flex items-center gap-1">
        <Card variant="secondary">
          <div className="relative flex flex-col px-3 py-5 sm:p-5">
            <p className="text-xs font-normal text-secondary">YOU PAY</p>
            <div className="flex items-center justify-between">
              <p className="text-xl font-normal text-secondary sm:text-2xl">
                {Number(trade?.sourceAmount).toFixed(6)}
              </p>
            </div>
            <div className="flex items-center gap-1">
              <p className="text-[9px] font-normal text-secondary sm:text-xs">
                {trade?.sourceCabn?.currency?.symbol}
              </p>
              <img
                src={trade?.sourceCabn?.currency?.logo}
                alt="source token icon"
                className="h-3 w-3 sm:h-4 sm:w-4"
              />
              <span className="text-[9px] font-normal text-secondary sm:text-xs">
                {" "}
                on {trade?.sourceNetwork?.name} Chain
              </span>
              <img
                src={trade?.sourceNetwork?.logo}
                alt="BSC"
                className="h-3 w-3 sm:h-4 sm:w-4"
              />
            </div>
          </div>
        </Card>
        <div className="relative mx-auto flex items-center rounded-xl bg-[#11131580] p-[8px]">
          <ReactIcons.FaArrowRight color="white" />
        </div>
        <Card variant="secondary">
          <div className="relative flex flex-col px-3 py-5 sm:p-5">
            <p className="text-xs font-normal text-secondary">YOU RECEIVED</p>
            <div className="flex items-center justify-between">
              <p className="text-xl font-normal text-secondary sm:text-2xl">
                {Number(trade?.destinationAmount).toFixed(6)}
              </p>
            </div>
            <div className="flex items-center gap-1">
              <p className="text-[9px] font-normal text-secondary sm:text-xs">
                {trade?.destinationCabn?.currency?.symbol}
              </p>
              <img
                src={trade?.destinationCabn?.currency?.logo}
                alt="destination token icon"
                className="h-3 w-3 sm:h-4 sm:w-4"
              />
              <span className="text-[9px] font-normal text-secondary sm:text-xs">
                {" "}
                on {trade?.destinationNetwork?.name} Chain
              </span>
              <img
                src={trade?.destinationNetwork?.logo}
                alt="BSC"
                className="h-3 w-3 sm:h-4 sm:w-4"
              />
            </div>
          </div>
        </Card>
      </div>
      <div className="my-3 flex items-center justify-around text-white">
        <div className="flex items-center">
          <p
            className="text-nowrap text-[9px]"
            onClick={() => {
              copyTextToClipboard(trade?.receiveTransactionId);
            }}
          >
            SOURCE HASH:{" "}
            {trade?.receiveTransactionId &&
              truncateMiddle(trade?.receiveTransactionId, 14)}
          </p>
          <a
            href={`${trade?.sourceNetwork?.blockExplorerUrl}/tx/${trade?.receiveTransactionId}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={Icons.ExplorerUrlIcon}
              alt="explorer url icon"
              className="ml-1 w-4 cursor-pointer sm:ml-2"
            />
          </a>
        </div>
        <div className="flex items-center">
          <p
            className="text-nowrap text-[9px]"
            onClick={() => {
              copyTextToClipboard(
                trade?.withdrawTransactions[0]?.transactionId,
              );
            }}
          >
            TARGET HASH:{" "}
            {trade?.withdrawTransactions[0]?.transactionId &&
              truncateMiddle(trade?.withdrawTransactions[0]?.transactionId, 14)}
          </p>
          <a
            href={`${trade?.destinationNetwork?.blockExplorerUrl}/tx/${trade?.withdrawTransactions[0]?.transactionId}`}
            target="_black"
            rel="noreferrer"
          >
            <img
              src={Icons.ExplorerUrlIcon}
              alt="explorer url icon"
              className="ml-1 w-4 cursor-pointer sm:ml-2"
            />
          </a>
        </div>
      </div>
      <Card
        variant="secondary"
        className="flex flex-col justify-center p-3 sm:flex-row sm:justify-between"
      >
        <div id="gas-price" className="flex items-center gap-1">
          <img src={Icons.GasIcon} alt="" />
          <p className=" text-xs font-normal text-secondary">
            Gas Fee:{" "}
            <span className=" text-xs font-bold text-primary">
              {trade?.gasPrices?.source?.gasPrice
                ? new Big(Number(trade?.gasPrices?.source?.gasPrice))
                    .add(Number(trade?.gasPrices?.destination?.gasPrice))
                    .toFixed(10)
                : undefined}
            </span>
            <span className="ml-2 text-xs font-bold text-primary">
              {trade?.sourceNetwork?.networkCurrencySymbol}
            </span>{" "}
          </p>
          <Tooltip
            className="!w-auto !to-backgroundDark !text-[10px] !text-secondary"
            anchorSelect="#gas-price"
          >
            {toolTipForGasFee()}
          </Tooltip>
        </div>
        <div id="slippage-fee" className="flex items-center gap-1">
          <ReactIcons.PiWarningCircle className=" h-[15px] w-[15px]" />
          <p className=" text-xs font-normal text-secondary">
            Slippage:{" "}
            <span className="text-xs font-bold text-primary">
              {trade?.sourceNetwork?.chainId ===
              trade?.destinationNetwork?.chainId
                ? trade?.sourceSlippage
                : trade?.sourceSlippage + trade?.destinationSlippage}
              %
            </span>
          </p>
          <Tooltip
            className="!w-auto !to-backgroundDark !text-[10px] !text-secondary"
            anchorSelect="#slippage-fee"
          >
            {toolTipForSlippage()}
          </Tooltip>
        </div>
        <div className="flex items-center">
          <a id="calculated-time">
            <img src={Icons.TimerIcon} alt="timer icon" />
          </a>

          <p className="ml-1 text-xs font-normal text-secondary">
            Completed In:{" "}
            <span className="text-xs font-bold text-primary">
              {`${calculateSwapTime()} sec`}
            </span>
          </p>
          <Tooltip
            className="!w-auto !to-backgroundDark !text-[10px] !text-secondary"
            anchorSelect="#calculated-time"
          >{`Est. time ${calculateSwapTime()} sec`}</Tooltip>
        </div>
      </Card>
      <Card
        variant="secondary"
        className="mt-5 flex items-center justify-between p-3"
      >
        <div className="flex items-center gap-1">
          <img
            src={Icons.ReceiverWalletIcon}
            alt="receiver wallet icon"
            className="mr-1"
          />
          <p className="text-xs font-normal text-secondary">
            Receiver Address:{" "}
            <span className="text-[10px] font-bold text-primary sm:text-xs">
              {trade?.destinationWalletAddress}
            </span>
          </p>
          <a
            href={`${trade?.sourceNetwork?.blockExplorerUrl}address/${trade?.destinationWalletAddress}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={Icons.ExplorerUrlIcon}
              alt="explorer url icon"
              width={18}
            />
          </a>
        </div>
      </Card>
      <div className="mt-5">
        <Card variant="secondary" className="flex flex-col p-3">
          <img
            src={Images.FiberEngineLogo}
            alt="fiber engine logo"
            className="h-5"
          />
          <div className="mt-2 flex items-center justify-evenly">
            <div className="flex items-center">
              <p className="mr-1 text-xs sm:text-sm">
                {trade?.sourceAssetType} Asset
              </p>
              <img
                src={trade?.sourceNetwork?.logo}
                alt="source asset logo"
                className="h-5 w-5"
              />
            </div>
            <div className="rounded-xl border border-background bg-[#11131580] p-[8px]">
              <ReactIcons.FaArrowRight color="white" />
            </div>
            <div className="flex items-center">
              <p className="mr-1 text-xs sm:text-sm">
                {trade?.destinationAssetType} Asset
              </p>
              <img
                src={trade?.destinationNetwork?.logo}
                alt="destination asset logo"
                className="h-5 w-5"
              />
            </div>
          </div>
        </Card>
      </div>
    </Dialog>
  );
};

export default TradeDetailDialog;
