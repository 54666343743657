import React, { useEffect, useState } from "react";
import { Card, Button, InputField } from "../../components/ui";
import { Gif, Icons, Images, ReactIcons } from "../../assets";
import SwapDetailsSelectorCard from "./SwapDetailsSelectorCard";
import SlippageDialog from "../../components/SlippageDialog";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ConnectWalletDialog from "../../components/ConnectWalletDialog";
import SwapFeeDetailCard from "./SwapFeeDetailCard";
import { Tooltip } from "react-tooltip";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  allowanceState,
  destinationAddressState,
  destinationNetworkState,
  destinationTokenState,
  isApprovingState,
  estimatedSwapTimeState,
  fetchingGasFeeEstimationState,
  fetchingQuotationState,
  gasFeeEstimationState,
  isSwappingState,
  sourceNetworkState,
  sourceTokenState,
  swapAndWithdrawTxState,
  swapAndWithdrawTxStatusState,
  tokenQuotationInfoState,
  withdrawTxHashState,
  ErrorMessagesState,
  swapTxHashState,
  isCCTPState,
  infoMessagesState,
} from "../../recoil/swap";

import {
  useWalletConnector,
  useFoundrySendTransaction,
  useSwitchNetwork,
} from "foundry";
import ApproveRouterJson from "../../utils/abi/ApproveRouterAbi.json";
import { AbiItem } from "web3-utils";
import ApproveCard from "./ApproveCard";
import { IsObjectEmpty } from "../../utils/helper";
import { Web3Helper } from "../../utils/Web3Helper";
import * as swapServerRequest from "../../api/SwapCrud";
import Big from "big.js";
import Web3 from "web3";
import { authTokenState } from "../../recoil/auth";
import {
  SwapAndWithdrawTxStatuses,
  FailedStatuses,
  SwapAndWithdrawErrorMessages,
  getSwapFailedHelpLink,
  getSwapInsufficientFundsHelpLink,
} from "../../utils/constants";
import { AxiosError } from "axios";
import { useTokenBalance } from "../../hooks";
import SwapPlatformFeeCard from "./SwapPlatformFeeCard";

const SwapCard = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const amount = searchParams.get("amount");
  const authToken = useRecoilValue(authTokenState);
  const sourceNetwork = useRecoilValue(sourceNetworkState);
  const sourceToken = useRecoilValue(sourceTokenState);
  const destinationNetwork = useRecoilValue(destinationNetworkState);
  const destinationToken = useRecoilValue(destinationTokenState);
  const estimatedSwapTime = useRecoilValue(estimatedSwapTimeState);
  const [errorMessage, setErrorMessage] = useRecoilState(ErrorMessagesState);
  const setInfoMessage = useSetRecoilState(infoMessagesState);
  const [isSwapping, setIsSwapping] = useRecoilState(isSwappingState);
  const [tokenQuotationInfo, setTokenQuotationInfo] = useRecoilState(
    tokenQuotationInfoState,
  );
  const [destinationAddress, setDestinationAddress] = useRecoilState(
    destinationAddressState,
  );

  const [fetchingQuotation, setFetchingQuotation] = useRecoilState(
    fetchingQuotationState,
  );
  const [fetchingGasFeeEstimation, setFetchingGasFeeEstimation] =
    useRecoilState(fetchingGasFeeEstimationState);
  const [estimatedGasFee, setEstimatedGasFee] = useRecoilState(
    gasFeeEstimationState,
  );
  const [swapAndWithdrawTxStatus, setSwapAndWithdrawTxStatus] = useRecoilState(
    swapAndWithdrawTxStatusState,
  );
  const [swapHash, setSwapHash] = useRecoilState(swapTxHashState);
  const [withdrawTxHash, setWithdrawTxHash] =
    useRecoilState(withdrawTxHashState);
  const [isApproving, setIsApproving] = useRecoilState(isApprovingState);
  const [allowance, setAllowance] = useRecoilState(allowanceState);
  const [showAddressField, setShowAddressField] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showSlippage, setShowSlippage] = useState(false);
  const { isConnected, walletAddress, currentNetworkChainId } =
    useWalletConnector();
  const { switchWeb3Network } = useSwitchNetwork();
  const { tokenBalance } = useTokenBalance(
    walletAddress,
    sourceToken?.tokenContractAddress,
    sourceToken?.isNative,
    sourceNetwork?.rpcUrl,
  );
  const [isCCTP, setIsCCTP] = useRecoilState(isCCTPState);
  const { hash, reciept, status, error, reset, sendWeb3Transaction } =
    useFoundrySendTransaction();

  console.log({ reciept, hash, swapAndWithdrawTxStatus });

  useEffect(() => {
    if (
      !IsObjectEmpty(sourceNetwork) &&
      !IsObjectEmpty(sourceToken) &&
      walletAddress &&
      !sourceToken?.isNative
    ) {
      setEstimatedGasFee({});
      getCurrentApprovedAmount(
        sourceToken.tokenContractAddress,
        sourceNetwork.multiSwapFiberRouterSmartContractAddress,
        walletAddress,
        sourceNetwork.rpcUrl,
      );
    }
    if (
      !IsObjectEmpty(sourceNetwork) &&
      !IsObjectEmpty(sourceToken) &&
      walletAddress &&
      reciept?.status === "success" &&
      isApproving &&
      tokenQuotationInfo?.sourceTokenQuotationInfo &&
      !IsObjectEmpty(tokenQuotationInfo?.sourceTokenQuotationInfo) &&
      tokenQuotationInfo?.destinationTokenQuotationInfo &&
      !IsObjectEmpty(tokenQuotationInfo?.destinationTokenQuotationInfo) &&
      tokenQuotationInfo?.fakeDestinationTokenQuotationInfo &&
      !IsObjectEmpty(tokenQuotationInfo?.fakeDestinationTokenQuotationInfo)
    ) {
      reset();
      setIsApproving(false);
      setIsSwapping(false);
      if (sourceToken?.isNative) {
        getCurrentApprovedAmount(
          sourceToken.tokenContractAddress,
          sourceNetwork.multiSwapFiberRouterSmartContractAddress,
          walletAddress,
          sourceNetwork.rpcUrl,
        );
      }
      fetchGasFeeEstimationAction(
        walletAddress,
        sourceNetwork?.chainId,
        sourceToken?.tokenContractAddress,
        tokenQuotationInfo,
        destinationAddress,
        destinationNetwork?.chainId,
        destinationToken?.tokenContractAddress,
        isCCTP,
      );
    }
  }, [
    hash,
    isApproving,
    reciept?.status,
    sourceNetwork,
    sourceToken,
    tokenQuotationInfo,
  ]);

  useEffect(() => {
    if (hash && isApproving) {
      handleQuotationAction(
        walletAddress,
        sourceNetwork?.chainId,
        sourceToken?.tokenContractAddress,
        tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceAmount,
        destinationAddress,
        destinationNetwork?.chainId,
        destinationToken?.tokenContractAddress,
        searchParams.get("referralCode")!,
        false,
      );
    }
    if (hash && isSwapping && authToken) {
      handleSwapAndWithdrawAction(
        hash,
        sourceNetwork?._id,
        sourceToken?._id,
        tokenQuotationInfo,
        destinationNetwork?._id,
        destinationToken?._id,
        estimatedGasFee,
        authToken,
        isCCTP,
      );
    }
  }, [hash, isSwapping, isApproving]);

  useEffect(() => {
    if ((error as any)?.shortMessage) {
      setIsSwapping(false);
      setIsApproving(false);
      setErrorMessage((error as any)?.shortMessage);
    }
  }, [error]);

  useEffect(() => {
    if (isConnected) {
      setShowDialog(false);
    }
  }, [isConnected]);

  const switchSelections = () => {
    setEstimatedGasFee({});
    searchParams.set("fromChainId", destinationNetwork?.chainId);
    searchParams.set("toChainId", sourceNetwork?.chainId);
    searchParams.set("fromToken", destinationToken?.tokenContractAddress);
    searchParams.set("toToken", sourceToken?.tokenContractAddress);
    navigate(`?${createSearchParams(searchParams)}`);
  };

  const getTotalEstimatedGasFee = () => {
    if (estimatedGasFee?.destination?.gasPrice) {
      return new Big(Number(estimatedGasFee?.source?.gasPrice))
        .add(Number(estimatedGasFee?.destination?.gasPrice))
        .toFixed();
    } else if (estimatedGasFee?.source?.gasPrice) {
      return estimatedGasFee?.source?.gasPrice;
    }
    return undefined;
  };

  const handleDestinationAddressAction = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDestinationAddress(e.target.value);
    searchParams.set("to", e.target.value);
    navigate(`?${createSearchParams(searchParams)}`);
  };

  const handlePasteAction = async () => {
    const value = await navigator.clipboard.readText();
    setDestinationAddress(value);
    searchParams.set("to", value);
    navigate(`?${createSearchParams(searchParams)}`);
    // setShowAddressField(false);
  };

  const getCurrentApprovedAmount = async (
    sourceTokenContractAddress: string,
    fiberRouterContractAddress: string,
    walletAddress: string,
    sourceNetworkRpcUrl: string,
  ) => {
    const web3 = new Web3(sourceNetworkRpcUrl);
    const web3Helper = new Web3Helper(web3);
    const response = await web3Helper.getStandardContractAllocation(
      sourceTokenContractAddress,
      walletAddress,
      fiberRouterContractAddress,
    );
    setAllowance(response?.allowance);
  };

  const approveContractAllocation = async (
    tokenContractAddress: string,
    walletAddress: string,
    fiberRouterSmartContractAddress: string,
    web3Sdk: any,
    amount: Big,
    sendWeb3Transaction: any,
  ) => {
    try {
      setIsApproving(true);
      const contractInstance = new web3Sdk.eth.Contract(
        ApproveRouterJson.abi as AbiItem[],
        tokenContractAddress,
      );
      const decimals = await contractInstance.methods.decimals().call();
      const payload = {
        from: walletAddress,
        to: tokenContractAddress,
        data: contractInstance.methods
          .approve(
            fiberRouterSmartContractAddress,
            new Big(amount)
              .times(10 ** Number(decimals))
              .toFixed()
              .toString(),
          )
          .encodeABI(),
      };
      console.log({ payload });
      return sendWeb3Transaction(payload);
    } catch (error) {
      console.log("approving", error);
      setIsApproving(false);
      if (error instanceof Error) {
        setErrorMessage(error?.message);
      }
    }
  };

  const handleApproveAction = () => {
    if (
      !IsObjectEmpty(sourceNetwork) &&
      !IsObjectEmpty(sourceToken) &&
      !IsObjectEmpty(destinationNetwork) &&
      !IsObjectEmpty(destinationToken) &&
      amount
    ) {
      approveContractAllocation(
        sourceToken.tokenContractAddress,
        walletAddress,
        sourceNetwork.multiSwapFiberRouterSmartContractAddress,
        new Web3(sourceNetwork.rpcUrl),
        new Big(amount),
        sendWeb3Transaction,
      );
    }
  };

  const handleQuotationAction = async (
    sourceWalletAddress: string,
    sourceNetworkChainId: string,
    sourceTokenAddress: string,
    sourceAmount: string,
    destinationWalletAddress: string,
    destinationNetworkChainId: string,
    destinationTokenAddress: string,
    referralCode: string = "",
    doFetchGasFeeEstimation: boolean = false,
  ) => {
    console.log("handle action button");
    setFetchingQuotation(true);
    try {
      const quoteInfoResponse =
        await swapServerRequest.getTokenCategorizedAndQuoteInformation(
          sourceWalletAddress,
          sourceTokenAddress,
          sourceNetworkChainId,
          sourceAmount,
          destinationWalletAddress,
          destinationTokenAddress,
          destinationNetworkChainId,
          searchParams.get("sourceSlippage")!,
          searchParams.get("destinationSlippage")!,
          referralCode,
        );
      const {
        sourceTokenCategorizedInfo,
        destinationTokenCategorizedInfo,
        isCCTP,
        feeDistribution,
        platformFee,
      } = quoteInfoResponse.data.body.data;
      setIsCCTP(isCCTP);
      let fakeQuoteInfoResponse;
      if (sourceNetworkChainId !== destinationNetworkChainId) {
        fakeQuoteInfoResponse =
          await swapServerRequest.getFakeTokenCategorizedAndQuoteInformation(
            sourceWalletAddress,
            sourceTokenAddress,
            sourceNetworkChainId,
            sourceAmount,
            destinationWalletAddress,
            destinationTokenAddress,
            destinationNetworkChainId,
            searchParams.get("sourceSlippage")!,
            searchParams.get("destinationSlippage")!,
            "0.5",
          );
      }
      const fakeDestinationTokenCategorizedInfo =
        fakeQuoteInfoResponse?.data?.body?.data
          ?.destinationTokenCategorizedInfo;
      const updatedTokenQuotationInfo = {
        sourceTokenQuotationInfo: {
          ...sourceTokenCategorizedInfo,
          sourceSlippage: quoteInfoResponse.data.body.data.sourceSlippage,
        },
        destinationTokenQuotationInfo: {
          ...destinationTokenCategorizedInfo,
          destinationSlippage:
            quoteInfoResponse.data.body.data.destinationSlippage,
        },
        fakeDestinationTokenQuotationInfo: {
          ...fakeDestinationTokenCategorizedInfo,
          destinationSlippage:
            fakeQuoteInfoResponse?.data?.body?.data?.destinationSlippage,
        },
        feeDistribution: feeDistribution,
        platformFee,
      };
      setTokenQuotationInfo(updatedTokenQuotationInfo);
      setFetchingQuotation(false);
      if (doFetchGasFeeEstimation) {
        fetchGasFeeEstimationAction(
          sourceWalletAddress,
          sourceNetworkChainId,
          sourceTokenAddress,
          updatedTokenQuotationInfo,
          destinationWalletAddress,
          destinationNetworkChainId,
          destinationTokenAddress,
          isCCTP,
        );
      }
    } catch (error) {
      console.log({ error });
      setFetchingQuotation(false);
      if (error instanceof AxiosError) {
        setErrorMessage(error?.response?.data?.status?.message);
      } else if (error instanceof Error) {
        setErrorMessage(error?.message);
      }
    }
  };

  const fetchGasFeeEstimationAction = async (
    sourceWalletAddress: string,
    sourceNetworkChainId: string,
    sourceTokenAddress: string,
    tokenQuotationInfo: { [key: string]: any },
    destinationWalletAddress: string,
    destinationNetworkChainId: string,
    destinationTokenAddress: string,
    isCCTP: boolean,
  ) => {
    try {
      const {
        sourceTokenQuotationInfo,
        destinationTokenQuotationInfo,
        fakeDestinationTokenQuotationInfo,
        feeDistribution,
      } = tokenQuotationInfo;

      setFetchingGasFeeEstimation(true);
      const gasFeeResponse = await swapServerRequest.getGasFee(
        sourceNetworkChainId,
        sourceTokenAddress,
        sourceTokenQuotationInfo?.sourceAmount,
        sourceTokenQuotationInfo?.sourceAmountIn,
        sourceTokenQuotationInfo?.sourceAmountOut,
        sourceTokenQuotationInfo?.sourceOneInchData,
        sourceTokenQuotationInfo?.type,
        sourceTokenQuotationInfo?.sourceOneInchSelector,
        sourceWalletAddress,
        destinationNetworkChainId,
        destinationTokenAddress,
        sourceNetworkChainId === destinationNetworkChainId
          ? destinationTokenQuotationInfo?.destinationOneInchSelector
          : fakeDestinationTokenQuotationInfo?.destinationOneInchSelector,
        sourceNetworkChainId === destinationNetworkChainId
          ? destinationTokenQuotationInfo?.destinationAmountIn
          : fakeDestinationTokenQuotationInfo?.destinationAmountIn,
        sourceNetworkChainId === destinationNetworkChainId
          ? destinationTokenQuotationInfo?.destinationAmountOut
          : fakeDestinationTokenQuotationInfo?.destinationAmountOut,
        sourceNetworkChainId === destinationNetworkChainId
          ? destinationTokenQuotationInfo?.destinationOneInchData
          : fakeDestinationTokenQuotationInfo?.destinationOneInchData,
        sourceNetworkChainId === destinationNetworkChainId
          ? destinationTokenQuotationInfo?.type
          : fakeDestinationTokenQuotationInfo?.type,
        destinationWalletAddress
          ? destinationWalletAddress
          : sourceWalletAddress,
        destinationTokenQuotationInfo.destinationAmountIn,
        destinationTokenQuotationInfo?.destinationAmountOut,
        isCCTP,
        feeDistribution,
      );
      setFetchingGasFeeEstimation(false);
      setEstimatedGasFee(gasFeeResponse?.data?.body);
    } catch (error) {
      console.log({ error });
      if (error instanceof AxiosError) {
        if (
          error?.response?.data?.status?.message.includes("insufficient funds")
        ) {
          setErrorMessage("Insufficient funds to pay for gas fee");
        } else {
          setErrorMessage(error?.response?.data?.status?.message);
        }
      } else if (error instanceof Error) {
        setErrorMessage(error?.message);
      }
      setFetchingGasFeeEstimation(false);
    }
  };

  const handleConfirmSwapAction = async (
    sourceWalletAddress: string,
    sourceNetworkChainId: string,
    sourceTokenAddress: string,
    tokenQuotationInfo: { [key: string]: any },
    destinationWalletAddress: string,
    destinationNetworkChainId: string,
    destinationTokenAddress: string,
    estimatedGasFee: string,
    isCCTP: boolean,
  ) => {
    try {
      setIsSwapping(true);
      const response = await swapServerRequest.getSwapApi(
        sourceWalletAddress,
        sourceTokenAddress,
        sourceNetworkChainId,
        tokenQuotationInfo?.sourceTokenQuotationInfo?.type,
        tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceAmount,
        tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceAmountIn,
        tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceAmountOut,
        tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceOneInchData,
        tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceOneInchSelector,
        destinationWalletAddress,
        destinationNetworkChainId,
        destinationTokenAddress,
        tokenQuotationInfo?.destinationTokenQuotationInfo?.type,
        tokenQuotationInfo?.destinationTokenQuotationInfo
          ?.destinationOneInchData,
        tokenQuotationInfo?.destinationTokenQuotationInfo
          ?.destinationOneInchSelector,
        tokenQuotationInfo?.destinationTokenQuotationInfo?.destinationAmountIn,
        tokenQuotationInfo?.destinationTokenQuotationInfo?.destinationAmountOut,
        tokenQuotationInfo?.destinationTokenQuotationInfo?.destinationAmount,
        estimatedGasFee,
        isCCTP,
        tokenQuotationInfo?.feeDistribution,
      );

      if (response) {
        let transaction = response.data.body.data;
        console.log({ transaction });
        const payload = {
          from: transaction.from,
          to: transaction.contract,
          data: transaction.data,
          value:
            transaction.value !== "undefined" ? transaction.value : undefined,
        };
        sendWeb3Transaction(payload);
      }
    } catch (error) {
      console.log(error);
      setIsSwapping(false);
      if (error instanceof AxiosError) {
        setErrorMessage(error?.response?.data?.status?.message);
      } else if (error instanceof Error) {
        setErrorMessage(error?.message);
      }
    }
  };

  const handleSwapAndWithdrawAction = async (
    hash: string,
    sourceNetworkId: string,
    sourceCabnId: string,
    tokenQuotationInfo: { [key: string]: any },
    destinationNetworkId: string,
    destinationCabnId: string,
    estimatedGasFee: { [key: string]: any },
    authToken: string,
    isCCTP: boolean,
  ) => {
    try {
      reset();
      setSwapHash(hash);
      let txStatus = "";
      if (isCCTP) {
        setInfoMessage({
          message:
            "This transaction is processed through CCTP and can take up to 30 mins to complete.",
          keepVisible: true,
        });
      }
      while (txStatus !== "swapWithdrawCompleted") {
        const response =
          await swapServerRequest.doSwapAndWithdrawTxAfterSwapApi(
            hash,
            sourceNetworkId,
            sourceCabnId,
            tokenQuotationInfo?.sourceTokenQuotationInfo?.type,
            tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceAmount,
            walletAddress,
            tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceBridgeAmount,
            tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceOneInchData,
            tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceOneInchSelector,
            destinationNetworkId,
            destinationCabnId,
            tokenQuotationInfo?.destinationTokenQuotationInfo?.type,
            tokenQuotationInfo?.destinationTokenQuotationInfo
              ?.destinationOneInchData,
            tokenQuotationInfo?.destinationTokenQuotationInfo
              ?.destinationAmountIn,
            tokenQuotationInfo?.destinationTokenQuotationInfo
              ?.destinationAmountOut,
            tokenQuotationInfo?.destinationTokenQuotationInfo
              ?.destinationOneInchSelector,
            estimatedGasFee,
            searchParams.get("sourceSlippage") ||
              tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceSlippage,
            searchParams.get("destinationSlippage") ||
              tokenQuotationInfo?.destinationTokenQuotationInfo
                ?.destinationSlippage,
            authToken,
            isCCTP,
          );
        const transactionReceipt =
          response?.data?.body?.swapAndWithdrawTransaction;

        setSwapAndWithdrawTxStatus(transactionReceipt?.status);
        const withdrawTxHash =
          transactionReceipt?.withdrawTransactions?.shift()?.transactionId;
        setWithdrawTxHash(withdrawTxHash);
        if (
          transactionReceipt?.status === "swapWithdrawCompleted" &&
          withdrawTxHash
        ) {
          setSwapHash("");
          setWithdrawTxHash("");
          setIsSwapping(false);
          setTokenQuotationInfo({});
          setEstimatedGasFee({});
          setDestinationAddress("");
          setSwapAndWithdrawTxStatus("");
          setInfoMessage({
            message: "",
            keepVisible: false,
          });
          navigate(`/swap-success/${hash}`);
          break;
        }
        if (
          transactionReceipt?.status === "swapFailed" ||
          transactionReceipt?.status === "swapWithdrawFailed" ||
          transactionReceipt?.status === "generatorSignatureFailed" ||
          transactionReceipt?.status === "validatorSignatureFailed" ||
          transactionReceipt?.status === "masterValidationFailed"
        ) {
          setIsSwapping(false);
          setSwapHash("");
          setEstimatedGasFee({});
          setErrorMessage("Swap Failed");
          break;
        }
        await new Promise((res) => setTimeout(res, 2000));
      }
    } catch (error) {
      console.log(error);
      if (error instanceof AxiosError) {
        setErrorMessage(error?.response?.data?.status?.message);
      } else if (error instanceof Error) {
        setErrorMessage(error?.message);
      }
    }
  };

  const getButtonVariant = () => {
    if (
      !isConnected ||
      (sourceNetwork?.chainId &&
        destinationNetwork?.chainId &&
        currentNetworkChainId !== Number(sourceNetwork?.chainId) &&
        !fetchingQuotation)
    )
      return "primary";

    if (
      isSwapping ||
      fetchingQuotation ||
      fetchingGasFeeEstimation ||
      (swapAndWithdrawTxStatus &&
        !FailedStatuses.includes(swapAndWithdrawTxStatus)) ||
      !amount ||
      IsObjectEmpty(sourceNetwork) ||
      IsObjectEmpty(sourceToken) ||
      IsObjectEmpty(destinationNetwork) ||
      IsObjectEmpty(destinationToken)
    ) {
      return "tertiary";
    }

    return "primary";
  };

  const getButtonTitle = () => {
    if (errorMessage && errorMessage.includes("Insufficient funds")) {
      return errorMessage;
    }
    if (fetchingQuotation) return "Fetching Latest Quote";
    if (fetchingGasFeeEstimation) return "Fetching Gas Fee";
    if (!isConnected) return "Connect Wallet";
    if (FailedStatuses.includes(swapAndWithdrawTxStatus)) {
      return "Swap Failed! Click for more info";
    }
    if (
      swapAndWithdrawTxStatus &&
      !FailedStatuses.includes(swapAndWithdrawTxStatus)
    )
      return SwapAndWithdrawTxStatuses[swapAndWithdrawTxStatus];
    if (
      sourceNetwork?.chainId &&
      destinationNetwork?.chainId &&
      currentNetworkChainId !== Number(sourceNetwork?.chainId)
    )
      return "Switch Network";
    if (
      IsObjectEmpty(sourceNetwork) ||
      IsObjectEmpty(sourceToken) ||
      IsObjectEmpty(destinationNetwork) ||
      IsObjectEmpty(destinationToken)
    )
      return "Select Network and Token";
    if (!amount) return "Enter an Amount";
    if (isSwapping || getTotalEstimatedGasFee()) return "CONFIRM SWAP";
    if (
      Number(allowance) < Number(amount) ||
      estimatedGasFee?.destination?.gasPriceInMachine
    )
      return "SWAP";

    return "SWAP";
  };

  const isButtonDisabled = () => {
    return (
      ((!amount && currentNetworkChainId === Number(sourceNetwork?.chainId)) ||
        fetchingQuotation ||
        fetchingGasFeeEstimation ||
        (isSwapping && !(swapHash || withdrawTxHash)) ||
        isApproving ||
        !sourceNetwork?.chainId ||
        !destinationNetwork?.chainId) &&
      isConnected
    );
  };

  const getButtonPostfix = () => {
    if (
      (isSwapping && (withdrawTxHash || swapHash)) ||
      FailedStatuses.includes(swapAndWithdrawTxStatus) ||
      (errorMessage && errorMessage.includes("Insufficient funds"))
    ) {
      return <ReactIcons.GoLinkExternal />;
    }
  };

  const getButtonPrefix = () => {
    if (
      isSwapping ||
      isApproving ||
      (fetchingQuotation && isConnected) ||
      (fetchingGasFeeEstimation && isConnected) ||
      (swapAndWithdrawTxStatus &&
        !FailedStatuses.includes(swapAndWithdrawTxStatus))
    ) {
      return <img src={Gif.Loader} className="h-4 w-4" />;
    }
  };

  const handleButtonClick = () => {
    if (
      isConnected &&
      sourceNetwork?.chainId &&
      destinationNetwork?.chainId &&
      sourceToken?.currency?.symbol &&
      destinationToken?.currency?.symbol &&
      sourceNetwork?.chainId === destinationNetwork?.chainId &&
      sourceToken?.currency?.symbol === destinationToken?.currency?.symbol
    ) {
      setErrorMessage("Source and destination cannot be the same.");
      return;
    }
    if (errorMessage && errorMessage.includes("Insufficient funds")) {
      window.open(getSwapInsufficientFundsHelpLink(), "_blank");
      setErrorMessage("");
    }
    if (FailedStatuses.includes(swapAndWithdrawTxStatus)) {
      window.open(getSwapFailedHelpLink(), "_blank");
      setSwapAndWithdrawTxStatus("");
      return;
    }
    if (isSwapping && (swapHash || withdrawTxHash)) {
      const link = withdrawTxHash
        ? `${destinationNetwork?.blockExplorerUrl}/tx/${withdrawTxHash}`
        : swapHash
          ? `${sourceNetwork?.blockExplorerUrl}/tx/${swapHash}`
          : "";
      window.open(link, "_blank");
      return;
    } else if (!isConnected) {
      setShowDialog(true);
    } else if (
      amount?.includes(".") &&
      amount?.split(".")?.length &&
      Number(amount?.split(".")[1].length) > sourceToken?.decimals
    ) {
      setErrorMessage(
        `Amount should have ${sourceToken?.decimals} decimal places`,
      );
      return;
    } else if (
      sourceNetwork?.chainId &&
      destinationNetwork?.chainId &&
      currentNetworkChainId !== Number(sourceNetwork?.chainId)
    ) {
      switchWeb3Network(sourceNetwork?.chainId);
    } else if (
      !(Number(amount) > 0 && Number(amount) <= Number(tokenBalance))
    ) {
      setErrorMessage(
        `You don't have that much ${sourceToken?.currency?.symbol} tokens to swap`,
      );
    } else if (
      amount &&
      !sourceToken?.isNative &&
      Number(allowance) < Number(amount)
    ) {
      console.log({
        amount,
        allowance,
        amountInFloat: new Big(amount!).valueOf(),
        allowanceInFloat: new Big(allowance!).valueOf(),
        amountInNumber: Number(amount),
        allowanceInNumber: Number(allowance),
        condInBig: new Big(allowance!).lt(new Big(amount!)),
        cond: Number(allowance) < Number(amount),
      });
      setErrorMessage("");
      handleApproveAction();
    } else if (!IsObjectEmpty(estimatedGasFee)) {
      setErrorMessage("");
      handleConfirmSwapAction(
        walletAddress,
        sourceNetwork?.chainId,
        sourceToken?.tokenContractAddress,
        tokenQuotationInfo,
        destinationAddress,
        destinationNetwork?.chainId,
        destinationToken?.tokenContractAddress,
        estimatedGasFee?.destination?.gasPriceInMachine
          ? estimatedGasFee?.destination?.gasPriceInMachine
          : "",
        isCCTP,
      );
    } else if (
      !IsObjectEmpty(tokenQuotationInfo) &&
      tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceAmount
    ) {
      setErrorMessage("");
      handleQuotationAction(
        walletAddress,
        sourceNetwork?.chainId,
        sourceToken?.tokenContractAddress,
        tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceAmount,
        destinationAddress,
        destinationNetwork?.chainId,
        destinationToken?.tokenContractAddress,
        searchParams.get("referralCode")!,
        true,
      );
    }
  };

  if (isApproving) {
    return <ApproveCard />;
  }

  return (
    <>
      <div className="mb-10 flex flex-col">
        <Card variant="primary" className="shadow-2xl">
          <section className="flex flex-col">
            <header className="flex h-8 items-center justify-between">
              <div className="text-base text-white">
                {!IsObjectEmpty(estimatedGasFee) ? (
                  <div className="flex items-center">
                    <ReactIcons.IoArrowBack
                      size={20}
                      className="cursor-pointer"
                      onClick={() => setEstimatedGasFee({})}
                    />
                    <span className="ml-2 text-sm sm:text-base">
                      Swap Preview
                    </span>
                  </div>
                ) : (
                  <span>MultiSwap</span>
                )}
              </div>
              <div className="flex items-center">
                <div className="mr-1 sm:mr-4">
                  <Button
                    variant="secondary"
                    postfix={
                      <div className="flex items-center justify-around">
                        {/* <img
                          src={Icons.SwapIcon}
                          alt="ms icon"
                          width={15}
                          className="mr-2"
                        /> */}
                        <span className="text-nowrap text-white">
                          Coming Soon
                        </span>
                        <ReactIcons.RiArrowDropDownLine
                          size={25}
                          color="white"
                        />
                      </div>
                    }
                    title="Fee Token:"
                    rounded="md"
                    className="h-8 text-nowrap p-2 text-xs"
                    style={{ color: "#6F767E" }}
                  />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => setShowSlippage(true)}
                >
                  <img src={Icons.SettingIcon} alt="setting icon" />
                </div>
              </div>
            </header>
            <article className="relative">
              <SwapDetailsSelectorCard title="YOU PAY" side="source" />

              <div
                className="mx-auto w-9 cursor-pointer rounded-xl border-2 border-background bg-[#15181A] p-[8px] sm:absolute sm:left-[15.5rem] sm:top-[7.3rem]"
                onClick={() => switchSelections()}
              >
                <ReactIcons.FaArrowDown color="white" />
              </div>

              <SwapDetailsSelectorCard title="YOU GET" side="destination" />

              {getTotalEstimatedGasFee() ? (
                <SwapFeeDetailCard
                  platformFee={tokenQuotationInfo?.platformFee}
                  sourceNetworkCurrency={sourceNetwork?.networkCurrencySymbol}
                  estimatedGasFee={getTotalEstimatedGasFee()}
                  estimatedSwapTime={
                    IsObjectEmpty(estimatedSwapTime) ? "80" : estimatedSwapTime
                  }
                  slippage={
                    Number(searchParams.get("sourceSlippage")) +
                    Number(searchParams.get("destinationSlippage"))
                  }
                />
              ) : tokenQuotationInfo?.platformFee ? (
                <SwapPlatformFeeCard
                  platformFee={tokenQuotationInfo?.platformFee}
                />
              ) : null}

              <section
                className={`flex ${showAddressField ? "h-16 flex-col " : "justify-between"} rounded-md bg-[#11131580] px-2 sm:h-8 sm:flex-row sm:items-center sm:justify-between sm:bg-inherit`}
              >
                <div className="flex items-center text-white">
                  <a id="send-to">
                    <ReactIcons.IoInformationCircleOutline />
                  </a>
                  <Tooltip
                    anchorSelect="#send-to"
                    className="!w-auto !to-backgroundDark !text-[10px] !text-secondary"
                  >
                    Swaps are received on same wallet. To send funds to
                    different wallet enter it below
                  </Tooltip>
                  <span className="ml-2 py-1 text-[10px]">
                    Send to a different wallet
                  </span>
                </div>

                {showAddressField ? (
                  <div className="flex w-full justify-between rounded-md bg-backgroundDark p-1 text-xs sm:w-[60%]">
                    <InputField
                      value={destinationAddress}
                      type="text"
                      placeholder="Enter Destination Wallet Address"
                      className="w-full px-2 text-white"
                      inputFieldClassName="w-full"
                      onChange={(e) => handleDestinationAddressAction(e)}
                    />
                    <Button
                      variant="tertiary"
                      title="PASTE"
                      className="py-1 text-[10px]"
                      onClick={() => handlePasteAction()}
                    />
                  </div>
                ) : (
                  <button
                    className="block whitespace-nowrap rounded-lg bg-inherit text-[10px] text-primary sm:bg-backgroundDark sm:p-2 sm:text-xs sm:text-secondary"
                    onClick={() => setShowAddressField(true)}
                  >
                    Add Address
                  </button>
                )}
              </section>
              <Button
                variant={`${getButtonVariant()}`}
                title={`${getButtonTitle()}`}
                className="mt-4 w-full text-xl"
                prefix={getButtonPrefix()}
                postfix={getButtonPostfix()}
                disabled={isButtonDisabled()}
                onClick={handleButtonClick}
              />
            </article>
          </section>
        </Card>
        <img src={Images.FerrumBanner} alt="ferrum banner" className="" />
      </div>
      <ConnectWalletDialog
        show={showDialog}
        onHide={() => setShowDialog(false)}
      />
      <SlippageDialog
        show={showSlippage}
        onHide={() => setShowSlippage(false)}
      />
    </>
  );
};

export default SwapCard;
