import React, { useEffect, useRef, useState } from "react";
import { Icons } from "../assets";
import { Link } from "react-router-dom";
import { getDocsURL, getExplorerURL, getSupportLink } from "../utils/constants";

const SideMenu = () => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isNavOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNavOpen]);

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsNavOpen(false);
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        data-collapse-toggle="navbar-dropdown"
        type="button"
        className="inline-flex h-10 w-10 items-center justify-center rounded-lg p-2 text-sm text-white"
        aria-controls="navbar-dropdown"
        aria-expanded="false"
        onClick={() => setIsNavOpen(!isNavOpen)}
      >
        <span className="sr-only">Open main menu</span>
        {isNavOpen ? (
          <img src={Icons.CrossIcon} alt="close Icon" />
        ) : (
          <img src={Icons.MenuIcon} alt="menu Icon" />
        )}
      </button>
      <div
        id="doubleDropdown"
        className={`${!isNavOpen ? "hidden" : ""} absolute right-0 top-12 w-36 rounded-sm bg-background shadow-2xl`}
      >
        <ul
          className="py-2 text-xs text-white"
          aria-labelledby="doubleDropdownButton"
        >
          <li>
            <Link to="/my-trades" className="block px-4 py-2">
              My Trades
            </Link>
          </li>
          <li>
            <Link to="/abandoned-tx" className="block px-4 py-2">
              Abandoned TX
            </Link>
          </li>
          <li>
            <a
              href={getExplorerURL()}
              target="_blank"
              className="block px-4 py-2"
            >
              Explorer
            </a>
          </li>
          {/* <li>
            <Link to="/transactions" className="block px-4 py-2">
              Transactions
            </Link>
          </li> */}
          <li>
            <Link to="/referral" className="block px-4 py-2">
              Referral
            </Link>
          </li>
          <li>
            <a href={getDocsURL()} target="_blank" className="block px-4 py-2">
              Docs
            </a>
          </li>
          <li>
            <a
              href={getSupportLink()}
              target="_blank"
              className="mx-4 block rounded-md bg-backgroundDark px-4 py-2 text-center"
            >
              Support
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
