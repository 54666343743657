import { atom } from "recoil";

export const authTokenState = atom({
  key: "authTokenState",
  default: "",
});

export const userState = atom({
  key: "userState",
  default: {} as any,
});
